import React, { useState, useEffect, useContext } from 'react';
import { Line } from 'react-chartjs-2';
import {
	Box,
	Text,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	TableContainer,
	Spinner
} from '@chakra-ui/react';
import ArcaSlider from '../Sliders/ArcaSlider';
import ArcaPrimaryButton from '../Buttons/ArcaPrimaryButton';
import ProjectConfig from '../../constants';
import { ChartOptions, Chart as ChartJS, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import SectionTitle from '../Titles/SectionTitle';
import { formatArcaNumberWithThousands } from '../../utils/formatArcaNumbers';
import { triggerMetricsWaveEvent } from '../../metricswave';
import { MetricswaveEvents, MetricswaveEventsAndParams } from '../../metricswave/MetricswaveEnums';
import { ArcaContext } from '../../context/ArcaContext.context';
import getCurrentUser from '../../api/users/getCurrentUser';
import { CurrentUserI } from '../../api/users/interfaces/Users ';
import getCompanyInfoEppConfig from '../../api/companies/getCompanyInfoEppConfig';
import { CompanyInfoI } from '../../api/companies/interfaces/CompanyInfo';
import {
	CompanyEppConfigI,
	EmployerContributionTypeEnum
} from '../../api/companies/interfaces/CompanyEppConfig';
import { ContributionTypeEnum } from '../../api/users/interfaces/ContributionData';
import getOptionByName from '../../api/generic-api/getOptionByName';

interface CompanyInfoAndEppConfigI {
	company: CompanyInfoI;
	companyEppConfig: CompanyEppConfigI;
}

// Registro de los elementos y plugin necesarios para Chart.js
ChartJS.register(...registerables, ChartDataLabels);

interface FinancialPlannerPensionSimulatorProps {
	currentAge: number;
}

const FinancialPlannerPensionSimulator: React.FC<FinancialPlannerPensionSimulatorProps> = ({
	currentAge
}) => {
	const { token, companyId } = useContext(ArcaContext);
	const [currentUser, setCurrentUser] = useState<CurrentUserI | null>(null);
	const [currentCompanyAndEppConfig, setCurrentCompanyAndEppConfig] =
		useState<CompanyInfoAndEppConfigI>();

	// Estado para la aportación del empleado
	const [employeeContribution, setEmployeeContribution] = useState<number>(50);
	const [companyContribution, setCompanyContribution] = useState<number>(50);
	const [historicalReturn, setHistoricalReturn] = useState<number>(5);

	// Estados para controlar el tamaño de la gráfica
	const [chartWidth, setChartWidth] = useState<number>(800);
	const [chartHeight, setChartHeight] = useState<number>(400);

	const retirementAge = 67;
	// Se calcula el número de años de ahorro desde los 29 hasta la jubilación.
	const yearsSaving = retirementAge - currentAge;
	// En la simulación, la aportación mensual total (empresa + empleado) se utiliza para el ahorro con capitalización compuesta
	const totalMonthlyContribution = companyContribution + employeeContribution;

	// Calcula el valor futuro acumulado de forma compuesta para "years" años
	const calculateFutureValue = (years: number, monthlyContribution: number) => {
		const monthlyRate = historicalReturn / 100 / 12;
		const totalMonths = years * 12;
		return (monthlyContribution * ((1 + monthlyRate) ** totalMonths - 1)) / monthlyRate;
	};

	// Calcula el valor futuro acumulado de forma simple (sin capitalización compuesta)
	const calculateSimpleSavings = (years: number, monthlyContribution: number) => {
		return monthlyContribution * 12 * years;
	};

	// Valor final acumulado a los 67 usando capitalización compuesta
	const finalCompoundValue = calculateFutureValue(yearsSaving, totalMonthlyContribution);
	// Ahorro simple mensual necesario para alcanzar ese valor, sin intereses
	const requiredSimpleMonthly = finalCompoundValue / (yearsSaving * 12);

	// Generamos las etiquetas de la gráfica: desde los 29 hasta los 67 (ambos inclusive)
	const chartLabels = Array.from(
		{ length: retirementAge - currentAge + 1 },
		(_, i) => currentAge + i
	);

	// Generamos los datos para cada punto.
	const compoundData = chartLabels.map((age) =>
		calculateFutureValue(age - currentAge, totalMonthlyContribution)
	);
	const simpleData = chartLabels.map((age) =>
		calculateSimpleSavings(age - currentAge, employeeContribution)
	);

	const chartData = {
		labels: chartLabels,
		datasets: [
			{
				label: 'Plan de pensiones de empleo (interés compuesto)',
				data: compoundData,
				borderColor: ProjectConfig.ARCA_COLORS.GREEN_600,
				backgroundColor: ProjectConfig.ARCA_COLORS.GREEN_600,
				pointRadius: 3,
				pointHoverRadius: 12,
				pointHoverBackgroundColor: ProjectConfig.ARCA_COLORS.GREEN_800,
				pointHoverBorderColor: ProjectConfig.ARCA_COLORS.WHITE,
				pointHoverBorderWidth: 2,
				fill: false
			},
			{
				label: 'Debajo del colchón (Ahorro Simple)',
				data: simpleData,
				borderColor: ProjectConfig.ARCA_COLORS.RED_500,
				backgroundColor: ProjectConfig.ARCA_COLORS.RED_500,
				pointRadius: 3,
				pointHoverRadius: 12,
				pointHoverBackgroundColor: ProjectConfig.ARCA_COLORS.RED_800,
				pointHoverBorderColor: ProjectConfig.ARCA_COLORS.WHITE,
				pointHoverBorderWidth: 2,
				fill: false
			}
		]
	};

	// Plugin personalizado para dibujar las líneas verticales en azul en los valores 67 y edad actual + 10.
	const verticalLinePlugin = {
		id: 'verticalLinePlugin',
		afterDatasetsDraw: (chart: any) => {
			const ctx = chart.ctx;
			const xScale = chart.scales.x;
			const yScale = chart.scales.y;

			const lineAges = [10, retirementAge - currentAge];

			ctx.save();
			ctx.strokeStyle = 'lightblue';
			ctx.lineWidth = 2;

			lineAges.forEach((age) => {
				const x = xScale.getPixelForValue(age);
				// Verificamos que la posición esté dentro de los límites visibles.
				if (x >= xScale.left && x <= xScale.right) {
					ctx.beginPath();
					ctx.moveTo(x, yScale.top);
					ctx.lineTo(x, yScale.bottom);
					ctx.stroke();
				}
			});
			ctx.restore();
		}
	};

	const options: ChartOptions<'line'> = {
		scales: {
			x: {
				grid: {
					display: false
				}
			},
			y: {
				beginAtZero: true
			}
		},
		interaction: {
			mode: 'index' as const,
			intersect: false
		},
		plugins: {
			tooltip: {
				mode: 'index' as const,
				intersect: false,
				callbacks: {
					// Se añade "Años" al label del eje X en el título
					title: (tooltipItems) => {
						const label = tooltipItems[0].label;
						return label + ' años';
					},
					// Se añade "€" al valor numérico de cada dato
					label: (tooltipItem) => {
						const datasetLabel = tooltipItem.dataset.label || '';
						const value = formatArcaNumberWithThousands(tooltipItem.parsed.y.toFixed(0));
						return datasetLabel ? `${datasetLabel}: ${value} €` : `${value} €`;
					}
				}
			},
			datalabels: {
				clip: false,
				offset: -45,
				display: function (context: any) {
					const dataLabel = context.chart.data.labels[context.dataIndex];
					const lastIndex = context.dataset.data.length - 1;
					const targetAge = currentAge + 10;
					return context.dataIndex === lastIndex || dataLabel === targetAge;
				},
				formatter: function (value: number) {
					return formatArcaNumberWithThousands(value.toFixed(0));
				},
				anchor: 'end',
				align: 'right',
				color: '#000',
				font: { weight: 'bold' }
			}
		},
		elements: {
			point: {
				radius: 3,
				hoverRadius: 4,
				borderWidth: 1
			}
		}
	};

	// Estados para controlar la visualización del gráfico y el spinner de carga
	const [showChart, setShowChart] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		const getCurrentUserAsync = async () => {
			const response = await getCurrentUser(token);
			const currentUserData = response.data as CurrentUserI;
			setCurrentUser(currentUserData);
			const userContribution =
				Number(currentUserData?.userEppConfig?.data?.pensionContribution) ?? 50;
			setEmployeeContribution(userContribution);
		};
		getCurrentUserAsync();

		const fetchCompanyInfoEppConfig = async () => {
			const currentCompanyAndEppConfig = await getCompanyInfoEppConfig(token, companyId);
			const currentCompanyAndEppConfigData =
				currentCompanyAndEppConfig.data as CompanyInfoAndEppConfigI;
			setCurrentCompanyAndEppConfig(currentCompanyAndEppConfigData);
			const companyContributionAmount =
				currentCompanyAndEppConfigData?.companyEppConfig?.employerContributionType ===
				EmployerContributionTypeEnum.FIXED_AMOUNT
					? Number(currentCompanyAndEppConfigData?.companyEppConfig?.employerContributionAmount)
					: (Number(currentCompanyAndEppConfigData?.companyEppConfig?.employerContributionAmount) *
							(currentUser?.user?.grossAnnualSalary ?? 0)) /
						12;
			setCompanyContribution(companyContributionAmount);
		};
		fetchCompanyInfoEppConfig();

		const getHistoricalProductReturn = async () => {
			const optionResponse = await getOptionByName(token, 'historical-product-return');
			const historicalProductReturn = Number(JSON.parse(optionResponse.data?.value));
			setHistoricalReturn(historicalProductReturn);
		};
		getHistoricalProductReturn();
	}, [token, companyId]);

	// Manejador del botón "Calcular gráfico"
	const handleCalculateChartClick = () => {
		setIsLoading(true);
		triggerMetricsWaveEvent(
			MetricswaveEventsAndParams[MetricswaveEvents.SIMULATORS_PENSION_SHOW_CHART].event,
			currentUser?.user?.id ?? '',
			{}
		);
		setTimeout(() => {
			setIsLoading(false);
			setShowChart(true);
		}, 2000);
	};

	return (
		<Box height="100%">
			<SectionTitle text="Simulador financiero plan de pensiones" />

			<Box width="50%" mx="auto" mt={2} mb={4}>
				<Text>
					Tu edad actual es:{' '}
					<Text
						as="span"
						mt={4}
						fontFamily={ProjectConfig.FONTS.SPACE_GROTESK}
						fontSize="xl"
						fontWeight="bold">
						{currentAge}
					</Text>
					, la edad de jubilación es:{' '}
					<Text
						as="span"
						mt={4}
						fontFamily={ProjectConfig.FONTS.SPACE_GROTESK}
						fontSize="xl"
						fontWeight="bold">
						{retirementAge}
					</Text>
				</Text>
				<Text>
					Aportación mensual de la empresa:{' '}
					<Text
						as="span"
						mt={4}
						fontFamily={ProjectConfig.FONTS.SPACE_GROTESK}
						fontSize="xl"
						fontWeight="bold">
						{formatArcaNumberWithThousands(companyContribution)} €
					</Text>
				</Text>
				<Text>
					Tu aportación mensual de empleado:{' '}
					<Text
						as="span"
						mt={4}
						fontFamily={ProjectConfig.FONTS.SPACE_GROTESK}
						fontSize="xl"
						fontWeight="bold">
						{formatArcaNumberWithThousands(employeeContribution)} €
					</Text>
				</Text>
				<ArcaSlider
					value={employeeContribution}
					min={0}
					max={500}
					step={10}
					onChange={setEmployeeContribution}
				/>
				{showChart && !isLoading && (
					<Text mt={2} fontSize="sm" color="gray.600">
						Ahorrando debajo del colchón, para alcanzar los{' '}
						<Text
							as="span"
							mt={4}
							fontFamily={ProjectConfig.FONTS.SPACE_GROTESK}
							fontSize="xl"
							fontWeight="bold">
							{formatArcaNumberWithThousands(finalCompoundValue.toFixed(0))} €
						</Text>{' '}
						te costaría{' '}
						<Text
							as="span"
							fontFamily={ProjectConfig.FONTS.SPACE_GROTESK}
							fontSize="xl"
							fontWeight="bold">
							{formatArcaNumberWithThousands(requiredSimpleMonthly.toFixed(0))} €
						</Text>{' '}
						al mes.
					</Text>
				)}
			</Box>

			{!showChart && (
				<TableContainer mt={6} mx="auto" mb={8}>
					<Table
						variant="simple"
						sx={{
							border: '2px solid',
							borderColor: 'gray.500',
							borderCollapse: 'collapse',
							'& th, & td': {
								border: '2px solid',
								borderColor: 'gray.500',
								padding: '8px'
							}
						}}>
						<Thead>
							<Tr>
								<Th textAlign="center">
									<Text fontWeight="bold">#</Text>
								</Th>
								<Th textAlign="center">
									<Text fontWeight="bold">Ventaja</Text>
								</Th>
								<Th textAlign="center">
									<Text fontWeight="bold">Descripción</Text>
								</Th>
							</Tr>
						</Thead>
						<Tbody>
							<Tr>
								<Td textAlign="center">1</Td>
								<Td>
									<Box display="inline-flex" alignItems="center">
										<Text fontSize="xl" mr={2}>
											💪🏽
										</Text>
										<Text fontWeight="bold" fontSize="sm">
											Fuerza de la empresa
										</Text>
									</Box>
								</Td>
								<Td>La contribución de la empresa incrementa significativamente tu fondo.</Td>
							</Tr>
							<Tr>
								<Td textAlign="center">2</Td>
								<Td>
									<Box display="inline-flex" alignItems="center">
										<Text fontSize="xl" mr={2}>
											💰
										</Text>
										<Text fontWeight="bold" fontSize="sm">
											Fuerza del rendimiento del ahorro
										</Text>
									</Box>
								</Td>
								<Td>
									Tus ahorros generan rendimientos sólidos y crecientes a lo largo del tiempo.
								</Td>
							</Tr>
							<Tr>
								<Td textAlign="center">3</Td>
								<Td>
									<Box display="inline-flex" alignItems="center">
										<Text fontSize="xl" mr={2}>
											💸
										</Text>
										<Text fontWeight="bold" fontSize="sm">
											Fuerza de no pagar IRPF
										</Text>
									</Box>
								</Td>
								<Td>Aprovechas ventajas fiscales al no tributar por tus aportaciones.</Td>
							</Tr>
							<Tr>
								<Td textAlign="center">4</Td>
								<Td>
									<Box display="inline-flex" alignItems="center">
										<Text fontSize="xl" mr={2}>
											📊
										</Text>
										<Text fontWeight="bold" fontSize="sm">
											Fuerza del interés compuesto
										</Text>
									</Box>
								</Td>
								<Td>
									El interés compuesto potencia el crecimiento de tu inversión exponencialmente.
								</Td>
							</Tr>
						</Tbody>
					</Table>
				</TableContainer>
			)}

			<Box display="flex" justifyContent="center" mb={4}>
				{!showChart && !isLoading && (
					<ArcaPrimaryButton onClick={handleCalculateChartClick}>
						Calcular gráfico
					</ArcaPrimaryButton>
				)}
				{isLoading && (
					<Spinner
						size="lg"
						thickness="7px"
						speed="1s"
						emptyColor={ProjectConfig.ARCA_COLORS.GREEN_400}
						color={ProjectConfig.ARCA_COLORS.GREEN_800}
					/>
				)}
			</Box>

			<Box mb={10}>
				{showChart && (
					<>
						<Line
							data={chartData}
							options={options}
							width={chartWidth}
							height={chartHeight}
							plugins={[ChartDataLabels, verticalLinePlugin]}
						/>
						<Text fontSize="sm" color="gray.600">
							* Los datos corresponden con una simulación con un rendimiento medio histórico en los
							últimos años del {historicalReturn}% anual.
						</Text>
					</>
				)}
			</Box>
		</Box>
	);
};

export default FinancialPlannerPensionSimulator;
