import { Box, Link, Text } from '@chakra-ui/react';

interface ConfidenceBoosterProps {
	haveLifeCyclesPlan: boolean;
}

const ConfidenceBoosterComponent: React.FC<ConfidenceBoosterProps> = ({ haveLifeCyclesPlan }) => {
	return (
		<Box display="flex" flexDirection="column" alignItems="center">
			<Box display="flex" justifyContent="center" mt={4} mb={4}>
				<img
					src="/imgs/cecabank-logo.png"
					alt="Cecabank"
					style={{ marginRight: '20px', maxWidth: '180px', height: 'auto', width: 'auto' }}
				/>
				<img
					src="/imgs/caser-helvetia.png"
					alt="Caser Helvetia"
					style={{ maxWidth: '150px', height: 'auto', width: 'auto' }}
				/>
			</Box>
			<Text fontSize="16px" maxWidth="600px">
				Tu plan de pensiones está gestionado por Arca y Caser Pensiones, asesorado por Indexa
				Capital y depositado en Cecabank
			</Text>
			<Text fontSize="16px" maxWidth="600px">
				Puedes buscar más detalles en{' '}
				<Link
					href="https://dgsfp.mineco.gob.es/"
					textDecoration="underline"
					target="_blank"
					rel="noopener noreferrer">
					https://dgsfp.mineco.gob.es/
				</Link>
				, en el apartado de "Registros públicos" y buscar por "Planes de Pensiones" o "Fondos de
				Pensiones" con los siguientes datos:
			</Text>
			<Text fontSize="16px" maxWidth="600px" textDecoration="underline">
				Acciones: Fondo F1831 Plan N5138
			</Text>
			{haveLifeCyclesPlan && (
				<Text fontSize="16px" maxWidth="600px" textDecoration="underline">
					Bonos: Fondo F1832 Plan N5137
				</Text>
			)}
			<Link
				href="https://www.bde.es/webbe/es/estadisticas/otras-clasificaciones/clasificacion-entidades/listas-instituciones-financieras/listas-fondos-pensiones-pais/lista-pf-es.html#"
				textDecoration="underline"
				target="_blank"
				rel="noopener noreferrer">
				Listado de fondos de pensiones de España (Banco de España)
			</Link>
		</Box>
	);
};

export default ConfidenceBoosterComponent;
