import { GenericBackendResponse } from '../../genericBackendResponse';
import { UserStatusType } from '../../users/interfaces/Users ';

// If you already have a defined UserStatusType somewhere, then import it instead.
// Otherwise, you might define it temporarily like so:
// export type UserStatusType = 'ACTIVE' | 'INACTIVE' | 'PENDING';

export interface PayfitUserData {
	id: string;
	name: string;
	dni: string;
	email: string;
	status: UserStatusType;
	nextPromoterContribution: number;
	totalPromoterContributions: number;
	nextParticipantContribution: number;
	totalParticipantContributions: number;
	totalEconomicsRightsGenerated: number;
}

export type GetPayfitFileDataResponse = GenericBackendResponse<PayfitUserData[]>;

const getPayfitFileData = async (
	token: string,
	companyId: string
): Promise<GetPayfitFileDataResponse> => {
	const response = await fetch(
		`${process.env.REACT_APP_BASE_API}/thirdparty/payfit/file-data/${companyId}`,
		{
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`
			}
		}
	);

	if (!response.ok) {
		throw new Error('Network response was not ok');
	}

	const result: GetPayfitFileDataResponse = await response.json();
	return result;
};

export default getPayfitFileData;
