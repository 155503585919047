import React, { useContext } from 'react';
import { Box, Button, Icon, Text } from '@chakra-ui/react';
import { FaDownload, FaFileAlt } from 'react-icons/fa';
import ProjectConfig from '../../constants';
import { I18nContext } from '../../context/translations/TranslationsContext.context';

interface DocumentDownloadProps {
	documentName: string;
	downloadLink: string;
}

const DocumentDownload: React.FC<DocumentDownloadProps> = ({ documentName, downloadLink }) => {
	const {
		state: { translate }
	} = useContext(I18nContext);

	return (
		<Box
			display="flex"
			flexDirection="column"
			alignItems="center"
			justifyContent="center"
			py="4"
			px="1"
			borderWidth="1px"
			borderRadius="xl"
			shadow="md"
			w="210px"
			h="200px"
			bg={ProjectConfig.ARCA_COLORS.WHITE}>
			<Icon as={FaFileAlt} w={12} h={12} color={ProjectConfig.ARCA_COLORS.GREEN_500} />
			<Text fontSize="lg" textAlign="center" m="auto" color={ProjectConfig.ARCA_COLORS.BLACK}>
				{documentName}
			</Text>
			<Button
				as="a"
				href={downloadLink}
				target="_blank"
				w="80%"
				p="3"
				mb="1"
				rel="noopener noreferrer"
				bg={ProjectConfig.ARCA_COLORS.PINK_300}
				color={ProjectConfig.ARCA_COLORS.WHITE}
				transition="all 0.2s ease-in-out"
				_hover={{ bg: ProjectConfig.ARCA_COLORS.PINK_500, transform: 'scale(1.03)' }}
				leftIcon={<Icon as={FaDownload} />}>
				{translate('user.employmentPlan.download')}
			</Button>
		</Box>
	);
};

export default DocumentDownload;
