import {
	Box,
	Text,
	Collapse,
	Button,
	VStack,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	Grid,
	Icon
} from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { I18nContext } from '../../../context/translations/TranslationsContext.context';
import SectionTitle from '../../../components/Titles/SectionTitle';
import getPortfolioLines, { Portfolio } from '../../../api/calculators/getPortfolioLines';
import { ArcaContext } from '../../../context/ArcaContext.context';
import ProjectConfig from '../../../constants';
import DocumentDownload from '../../../components/Documents/DocumentDownload';
import getCompanyInfoEppConfig from '../../../api/companies/getCompanyInfoEppConfig';
import EMPTY_COMPANY_INFO, { CompanyInfoI } from '../../../api/companies/interfaces/CompanyInfo';
import EMPTY_COMPANY_EPP_CONFIG, {
	CompanyEppConfigI
} from '../../../api/companies/interfaces/CompanyEppConfig';
import LoadingViewSpinner from '../../../components/ChakraUi/Spinners/LoadingViewSpinner';
import EMPTY_CURRENT_USER, { CurrentUserI } from '../../../api/users/interfaces/Users ';
import getCurrentUser from '../../../api/users/getCurrentUser';
import ConfidenceBoosterComponent from '../../../components/ConfidenceBoosterComponent';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';

interface PlanEmpleoUserProps {
	isFreelance: boolean;
}
interface CompanyInfoAndEppConfigI {
	company: CompanyInfoI;
	companyEppConfig: CompanyEppConfigI;
}
const emptyCompanyInfoAndEppConfig = {
	company: EMPTY_COMPANY_INFO,
	companyEppConfig: EMPTY_COMPANY_EPP_CONFIG
};

const PlanEmpleoUser: React.FC<PlanEmpleoUserProps> = ({ isFreelance }: PlanEmpleoUserProps) => {
	const {
		state: { translate }
	} = useContext(I18nContext);

	const { token, companyId } = useContext(ArcaContext);
	const [activeIndices, setActiveIndices] = useState<number[]>([]);
	const [portfolios, setPortfolios] = useState<Portfolio[]>([]);
	const [error, setError] = useState<string | null>(null);
	const [initialLoading, setInitialLoading] = useState<boolean>(true);
	const [companyEppInfo, setCompanyEppInfo] = useState<CompanyInfoAndEppConfigI>(
		emptyCompanyInfoAndEppConfig
	);
	const [currentUser, setCurrentUser] = useState<CurrentUserI>(EMPTY_CURRENT_USER);
	const [haveLifeCyclesPlan, setHaveLifeCyclesPlan] = useState<boolean>(true);

	useEffect(() => {
		setInitialLoading(true);

		const getCompanyInfoEppConfigAsync = async () => {
			if (typeof companyId === 'string' && companyId.trim().length > 0) {
				try {
					const currentCompanyInfoEppConfig = await getCompanyInfoEppConfig(token, companyId);
					const currentCompanyInfoEppConfigData =
						currentCompanyInfoEppConfig.data as CompanyInfoAndEppConfigI;
					setCompanyEppInfo(currentCompanyInfoEppConfigData);
				} catch (error) {
					console.error('Error fetching company info:', error);
				}
			}
		};

		if (!isFreelance) {
			getCompanyInfoEppConfigAsync();
		}

		const getCurrentUserAsync = async () => {
			const currentUser = await getCurrentUser(token);
			const currentUserData = currentUser.data as CurrentUserI;
			setCurrentUser(currentUserData);
		};
		getCurrentUserAsync();

		const fetchPortfolioLines = async () => {
			try {
				const response = await getPortfolioLines(token);
				setPortfolios(response.data);
				const hasLifeCyclesPlan = response.data.length > 1;
				setHaveLifeCyclesPlan(hasLifeCyclesPlan);
			} catch (err) {
				setError('Failed to fetch portfolio lines');
			}
		};
		fetchPortfolioLines();

		setTimeout(() => {
			setInitialLoading(false);
		}, 1000);
	}, [token]);

	const toggleCollapse = (index: number) => {
		if (activeIndices.includes(index)) {
			setActiveIndices(activeIndices.filter((i) => i !== index));
		} else {
			setActiveIndices([...activeIndices, index]);
		}
	};

	const currentUserAge = currentUser?.user?.birthdate
		? new Date().getFullYear() - new Date(currentUser.user.birthdate).getFullYear()
		: null;

	return (
		<div>
			{initialLoading ? (
				<Box h="80dvh" display="flex" justifyContent="center" alignItems="center">
					<LoadingViewSpinner />
				</Box>
			) : (
				<div>
					<SectionTitle text={translate('user.employmentPlan.info')} />

					{haveLifeCyclesPlan ? <Text>{translate('user.employmentPlan.lifeCycle')}</Text> : <></>}

					{/* Life Cycles Plan Table */}
					{haveLifeCyclesPlan && (
						<Table variant="simple" mt="8" size="sm" width="90%">
							<Thead>
								<Tr>
									<Th fontSize="sm" textAlign="center">
										{translate('user.employmentPlan.participantAge')}
									</Th>
									<Th fontSize="sm" textAlign="center">
										{translate('user.employmentPlan.subplanActions')}
									</Th>
									<Th fontSize="sm" textAlign="center">
										{translate('user.employmentPlan.subplanBonds')}
									</Th>
								</Tr>
							</Thead>
							<Tbody>
								{[18, 36, 46, 56, 66].map((ageGroup, index) => {
									const isCurrentUserAge =
										currentUserAge !== null &&
										currentUserAge >= ageGroup &&
										currentUserAge <= ageGroup + (index === 4 ? 0 : ageGroup === 18 ? 17 : 9);

									return (
										<Tr key={index} fontWeight={isCurrentUserAge ? 'bold' : 'normal'}>
											<Td fontSize="sm" textAlign="center" width="10%">
												{translate(
													`user.employmentPlan.age${ageGroup}To${ageGroup + (index === 4 ? 0 : ageGroup === 18 ? 17 : 9)}`
												)}
											</Td>
											<Td fontSize="sm" textAlign="center" width="10%">
												{80 - index * 10 + ' %'}
											</Td>
											<Td fontSize="sm" textAlign="center" width="10%">
												{20 + index * 10 + ' %'}
											</Td>
										</Tr>
									);
								})}
							</Tbody>
						</Table>
					)}

					<Text>{translate('user.employmentPlan.detail')}</Text>
					{error && <Text color="red">{error}</Text>}

					{portfolios.map((portfolio, index) => (
						<Box key={portfolio.portfolioCode} mb="4">
							<Button
								onClick={() => toggleCollapse(index)}
								w="100%"
								bg={ProjectConfig.ARCA_COLORS.PINK_200}
								color="black"
								_hover={{ bg: ProjectConfig.ARCA_COLORS.PINK_300 }}>
								<Box display="flex" alignItems="center">
									<Icon
										as={activeIndices.includes(index) ? ChevronUpIcon : ChevronDownIcon}
										boxSize={6}
										mr={2}
									/>
									<Text>{portfolio.portfolioCode}</Text>
									<Icon
										as={activeIndices.includes(index) ? ChevronUpIcon : ChevronDownIcon}
										boxSize={6}
										ml={2}
									/>
								</Box>
							</Button>
							<Collapse in={activeIndices.includes(index)} animateOpacity style={{ width: '100%' }}>
								<Box w="100%" p="5" mt="4" borderWidth="1px" borderRadius="md" shadow="md">
									<VStack spacing="4" align="stretch" w="100%">
										{/* Header row */}
										<Box
											display="flex"
											justifyContent="space-between"
											p="2"
											borderBottom="1px"
											borderColor="gray.200"
											bg="gray.100"
											w="100%">
											<Text w="20%">{translate('user.employmentPlan.etfTitle')}</Text>
											<Text w="10%">{translate('user.employmentPlan.type')}</Text>
											<Text w="15%">{translate('user.employmentPlan.index')}</Text>
											<Text w="10%">{translate('user.employmentPlan.currency')}</Text>
											<Text w="10%">{translate('user.employmentPlan.size')}</Text>
											<Text w="10%">{translate('user.employmentPlan.expenses')}</Text>
											<Text w="10%">{translate('user.employmentPlan.trackingError')}</Text>
											<Text w="10%">{translate('user.employmentPlan.weight')}</Text>
										</Box>
										{/* Data rows */}
										{portfolio.lines.map((line) => (
											<Box
												key={line.lineId}
												display="flex"
												justifyContent="space-between"
												p="2"
												borderBottom="1px"
												borderColor="gray.200"
												w="100%">
												<Text w="20%">{line.name}</Text>
												<Text w="10%">{line.assetClass}</Text>
												<Text w="15%">{line.indexName}</Text>
												<Text w="10%">{line.currency}</Text>
												<Text w="10%">{line.sizeMeur}</Text>
												<Text w="10%">{line.expenses}</Text>
												<Text w="10%">{line.trackingError}</Text>
												<Text w="10%">{line.weight}</Text>
											</Box>
										))}
									</VStack>
								</Box>
							</Collapse>
						</Box>
					))}

					<Grid templateColumns={{ base: '1fr', md: '1fr 1fr' }} gap={6}>
						<Box>
							<SectionTitle text={translate('admin.setBenefit.documents')} />
							<Grid templateColumns={{ base: '1fr', md: '1fr 1fr' }} gap={4}>
								<DocumentDownload
									documentName={translate('user.employmentPlan.actionsFundamentalData')}
									downloadLink={
										'https://drive.google.com/file/d/1IrSlXE6q2N8rwj6alz900bPOYIFh4BKP/view?usp=drive_link'
									}
								/>

								{haveLifeCyclesPlan && (
									<DocumentDownload
										documentName={translate('user.employmentPlan.boundsFundamentalData')}
										downloadLink={
											'https://drive.google.com/file/d/11pJSRA22upZ0I8PuDUsANcenmOuvQ_8J/view?usp=drive_link'
										}
									/>
								)}

								<DocumentDownload
									documentName={translate('user.employmentPlan.proforma')}
									downloadLink={
										isFreelance
											? currentUser.userEppConfig.linkRegulation
											: companyEppInfo.companyEppConfig.linkRegulation
									}
								/>
							</Grid>
						</Box>
						<Box bg={ProjectConfig.ARCA_COLORS.WHITE} p={4} borderRadius="md" shadow="md">
							<SectionTitle text={translate('admin.setBenefit.confidenceBooster')} />
							<ConfidenceBoosterComponent haveLifeCyclesPlan={haveLifeCyclesPlan} />
						</Box>
					</Grid>
				</div>
			)}
		</div>
	);
};

export default PlanEmpleoUser;
