import { ContributionPeriodicityEnum } from '../../companies/interfaces/CompanyEppConfig';
import { ContributionTypeEnum } from './ContributionData';

export enum UserGenderEnum {
	MEN = 'V',
	WOMEN = 'M'
}

export enum UserStatusType {
	ACTIVE = 'active',
	INACTIVE = 'non-active',
	DELETED = 'deleted'
}
// FIXME START Se podría dejar un solo interface pero habría que ir archivo a archivo cambiandolo todo.
export interface UserI {
	id: number;
	createdAt: string;
	updatedAt: string;
	email: string;
	password: string;
	name: string;
	phone: string;
	roles: any;
	firstSurname: string;
	lastSurname: string;
	gender: UserGenderEnum;
	language: string;
	nationality: string;
	country: string;
	nif: string;
	birthdate: string;
	companyName: string;
	companyCif: string;
	street: string;
	zip: string;
	province: string;
	population: string;
	bankAccount: string;
	lastLogin: string;
	verificationLink: string;
	encryptedData: string;
	status: string;
	companyId: string;
	representsCompany: boolean;
	representsParticipant: boolean;
	completedOnboarding: boolean;
	grossAnnualSalary: number;
}
//FIXME END

export interface UserEppConfigData {
	pensionContribution: number;
	contributionPeriodicity: ContributionPeriodicityEnum;
	employeeContributionType: ContributionTypeEnum;
}

export interface FreelanceEppConfigData {
	fondo: string;
	plan: string;
	subplan: string;
	pensionContribution: number;
	contributionPeriodicity: ContributionPeriodicityEnum;
	freelanceContributionType: ContributionTypeEnum;
	freelanceContributionDay: number;
	freelanceContributionPeriodicity: ContributionPeriodicityEnum;
}

export interface CurrentUserI {
	user: {
		id: string;
		createdAt: string;
		updatedAt: string;
		isFreelance: boolean;
		email: string;
		password: string;
		name: string;
		phone: string;
		roles: any;
		firstSurname: string;
		lastSurname: string;
		gender: UserGenderEnum;
		language: string;
		nationality: string;
		country: string;
		nif: string;
		birthdate: string;
		companyName: string;
		companyCif: string;
		street: string;
		zip: string;
		province: string;
		population: string;
		bankAccount: string;
		lastLogin: string;
		verificationLink: string;
		encryptedData: string;
		status: string;
		companyId: string;
		representsCompany: boolean;
		representsParticipant: boolean;
		completedOnboarding: boolean;
		grossAnnualSalary: number;
		lastMovementsUpdate: string;
	};
	userEppConfig: {
		id: string;
		createdAt: string;
		updatedAt: string;
		userId: string;
		status: string;
		data: {
			contributeAlwaysMax: boolean;
			fondo: string;
			plan: string;
			subplan: string;
			pensionContribution: number;
			contributionPeriodicity: string;
			freelanceContributionType: ContributionTypeEnum;
			freelanceContributionDay: number;
			freelanceContributionPeriodicity: ContributionPeriodicityEnum;
			employeeContributionType: ContributionTypeEnum;
		};
		lastContributionDate: string;
		linkRegulation: string;
	};
}

const EMPTY_CURRENT_USER: CurrentUserI = {
	user: {
		id: '',
		createdAt: '',
		updatedAt: '',
		isFreelance: false,
		email: '',
		password: '',
		name: '',
		phone: '',
		roles: [],
		firstSurname: '',
		lastSurname: '',
		gender: UserGenderEnum.MEN,
		language: '',
		nationality: '',
		country: '',
		nif: '',
		birthdate: '',
		companyName: '',
		companyCif: '',
		street: '',
		zip: '',
		province: '',
		population: '',
		bankAccount: '',
		lastLogin: '',
		verificationLink: '',
		encryptedData: '',
		status: '',
		companyId: '',
		representsCompany: false,
		representsParticipant: false,
		completedOnboarding: false,
		grossAnnualSalary: 0,
		lastMovementsUpdate: ''
	},
	userEppConfig: {
		id: '',
		createdAt: '',
		updatedAt: '',
		userId: '',
		status: '',
		data: {
			contributeAlwaysMax: false,
			fondo: '',
			plan: '',
			subplan: '',
			pensionContribution: 0,
			contributionPeriodicity: '',
			freelanceContributionType: ContributionTypeEnum.FIXED,
			freelanceContributionDay: 0,
			freelanceContributionPeriodicity: ContributionPeriodicityEnum.MONTHLY,
			employeeContributionType: ContributionTypeEnum.FIXED
		},
		lastContributionDate: '',
		linkRegulation: ''
	}
};

export default EMPTY_CURRENT_USER;
