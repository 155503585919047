import React from 'react';
import {
	Box,
	Image,
	Text,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	useDisclosure
} from '@chakra-ui/react';
import FinancialPlannerPensionSimulator from '../../../components/Simulators/FinancialPlannerPensionSimulator';
import SimulatorCard from '../../../components/ChakraUi/Cards/SimulatorCard';

interface SimulatorsProps {
	currentAge: number;
}

const Simulators: React.FC<SimulatorsProps> = ({ currentAge }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();

	return (
		<>
			{/* Tarjeta con borde y efecto hover para indicar que es clicable */}
			<SimulatorCard
				onClick={onOpen}
				imageSrc="/imgs/bed-money.png"
				text="Planificador plan de pensiones"
			/>

			{/* Modal que muestra el simulador */}
			<Modal isOpen={isOpen} onClose={onClose} size="5xl" isCentered motionPreset="slideInTop">
				<ModalOverlay />
				<ModalContent>
					<ModalCloseButton />
					<ModalBody>
						<FinancialPlannerPensionSimulator currentAge={currentAge} />
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
};

export default Simulators;
