// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../layers/google.nodejs.yarn/yarn_modules/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../layers/google.nodejs.yarn/yarn_modules/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-form {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.container {
  text-align: center;
}

input, button {
  display: block;
  margin: 10px auto;
  padding: 10px;
  width: 300px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

button {
  background-color: #333;
  color: #333;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #555;
}

p {
  margin-top: 10px;
  font-size: 14px;
  color: #555;
}
`, "",{"version":3,"sources":["webpack://./src/styles/LoginForm.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,iBAAiB;EACjB,aAAa;EACb,YAAY;EACZ,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,sBAAsB;EACtB,WAAW;EACX,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,WAAW;AACb","sourcesContent":[".login-form {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n}\n\n.container {\n  text-align: center;\n}\n\ninput, button {\n  display: block;\n  margin: 10px auto;\n  padding: 10px;\n  width: 300px;\n  border-radius: 5px;\n  border: 1px solid #ccc;\n}\n\nbutton {\n  background-color: #333;\n  color: #333;\n  border: none;\n  cursor: pointer;\n}\n\nbutton:hover {\n  background-color: #555;\n}\n\np {\n  margin-top: 10px;\n  font-size: 14px;\n  color: #555;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
