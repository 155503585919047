import {
	Box,
	Button,
	ListItem,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	OrderedList,
	Table,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	Input
} from '@chakra-ui/react';
import React, { useState, useEffect, useContext } from 'react';
import * as XLSX from 'xlsx';
import {
	columnIngresoEmpresa,
	columnModeloRetribucion,
	columnPeriodicity,
	columnReglasValoracion,
	columnType,
	columnVehiculoPropiedad,
	DropdownMapping,
	ModeloRetribucionOptions,
	PeriodicidadOptions,
	ColumnaIngresoEmpresaBoolean,
	TipoOptions
} from '../../../types/DropdownMapping';
import getPayfitFileData, {
	PayfitUserData
} from '../../../api/thirdparty/payfit/getPayfitFileData';
import { ArcaContext } from '../../../context/ArcaContext.context';
import { formatArcaNumber } from '../../../utils/formatArcaNumbers';
import ProjectConfig from '../../../constants';
import ArcaSecondaryButton from '../../Buttons/ArcaSecondaryButton';
import { CloseIcon } from '@chakra-ui/icons';
import SoftwareSelector, { SoftwareHHRR } from '../../Selector/SoftwareSelectorComponent';
import { triggerMetricsWaveEvent } from '../../../metricswave';
import {
	METRICS_WAVE_FUNNEL_PAYFIT_FEATURE,
	MetricswaveEvents,
	MetricswaveEventsAndParams
} from '../../../metricswave/MetricswaveEnums';

interface PayfitFileModalProps {
	isOpen: boolean;
	onClose: () => void;
}

const PayfitFileModal: React.FC<PayfitFileModalProps> = ({ isOpen, onClose }) => {
	const { token, companyId, userId } = useContext(ArcaContext);
	const [sheetData, setSheetData] = useState<any[][] | null>(null);
	const [dataVisible, setDataVisible] = useState(false);
	const [fileName, setFileName] = useState<string | null>(null);
	const [magicFilled, setMagicFilled] = useState<boolean>(false);
	const [tipoDropDownValues, setTipoDropDownValues] = useState<string[]>([]);
	const [payfitFileData, setPayfitFileData] = useState<PayfitUserData[]>();
	const [selectedSoftware, setSelectedSoftware] = useState<SoftwareHHRR>(SoftwareHHRR.SELECT);
	const [otherSoftware, setOtherSoftware] = useState<string>('');

	const handleCloseModal = (shouldCloseModal = true) => {
		setSheetData(null);
		setDataVisible(false);
		setFileName(null);
		setMagicFilled(false);
		setTipoDropDownValues([]);
		setOtherSoftware('');
		if (shouldCloseModal) onClose();
	};

	const handleInformArca = () => {
		triggerMetricsWaveEvent(
			MetricswaveEventsAndParams[MetricswaveEvents.NEW_HHRR_SOFTWARE_NOT_AVAILABLE].event,
			userId,
			{
				[MetricswaveEventsAndParams[MetricswaveEvents.NEW_HHRR_SOFTWARE_NOT_AVAILABLE].params
					.softwareHHRRSelected]: otherSoftware
			}
		);
		handleCloseModal();
	};

	const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
		const bodyParams = {
			step: METRICS_WAVE_FUNNEL_PAYFIT_FEATURE.CLICK_PAYFIT_FILE_UPLOAD
		};
		triggerMetricsWaveEvent('8daaff64-9c3b-4af2-ad2b-4655431673e3', userId, bodyParams);
		const file = e.target.files?.[0];
		if (!file) return;

		const reader = new FileReader();
		reader.onload = (evt) => {
			const bstr = evt.target?.result;
			// Leemos el archivo como binario y convertimos la primera hoja a un array de arrays
			let data = XLSX.utils.sheet_to_json(
				XLSX.read(bstr, { type: 'binary' }).Sheets[
					XLSX.read(bstr, { type: 'binary' }).SheetNames[0]
				],
				{ header: 1 }
			) as any[][];

			const last99Rows = data.slice(data.length - 99);

			// Elimino la última fila - Elimino las últimas 99 filas
			data = data.slice(0, data.length - 99);

			setFileName(file.name);

			// --- Extracción de la fila de configuración del dropdown (última posición) ---
			const dropdownConfigs = last99Rows.filter(
				(row) =>
					Array.isArray(row) &&
					row.length > 0 &&
					row.some((cell) => cell !== undefined && cell !== null && String(cell).trim() !== '')
			);

			// Seleccionamos (por defecto) la primera fila no vacía como configuración para el dropdown.
			// Si en el futuro se quiere dar la opción de elegir entre varias, se podría almacenar dropdownConfigs completo.
			if (dropdownConfigs.length > 0) {
				setTipoDropDownValues(dropdownConfigs[0]);
			}

			// --- Filtrar filas vacías (quedamos solo con aquellas que tengan al menos una celda con contenido) ---
			const nonEmptyRows = data.filter((row) =>
				row.some((cell) => cell !== undefined && cell !== null && String(cell).trim() !== '')
			);
			if (nonEmptyRows.length === 0) {
				setSheetData(null);
				return;
			}

			// --- Filtrar columnas vacías ---
			const maxColumns = nonEmptyRows.reduce((max, row) => Math.max(max, row.length), 0);
			const columnsToKeep: number[] = [];
			for (let col = 0; col < maxColumns; col++) {
				const hasContent = nonEmptyRows.some((row) => {
					const cell = row[col];
					return cell !== undefined && cell !== null && String(cell).trim() !== '';
				});
				if (hasContent) columnsToKeep.push(col);
			}

			const finalData = nonEmptyRows.map((row) =>
				columnsToKeep.map((colIndex) => row[colIndex] ?? '')
			);

			// Detectamos la cantidad de filas en el Excel importado y lo mostramos en consola
			const numRows = finalData.length;

			setSheetData(finalData);

			// Datos de debug (se asume que la primera fila es el header)
			const dataRows = finalData.slice(1);
			const payfitUuids = dataRows.map((row) => row[0]);
			const payfitNifs = dataRows.map((row) => row[2]);

			// Array con el orden de las columnas dropdown
			const dropdownKeys: (keyof DropdownMapping)[] = [
				columnType,
				columnPeriodicity,
				columnModeloRetribucion,
				columnIngresoEmpresa,
				columnReglasValoracion,
				columnVehiculoPropiedad
			];

			// Filtramos los arrays no vacíos de last99Rows (solo aquellos que tengan al menos una celda no vacía)
			const dropdownValuesArrays = last99Rows.filter(
				(row) =>
					Array.isArray(row) &&
					row.some((cell) => cell !== undefined && cell !== null && String(cell).trim() !== '')
			);

			// Creación del objeto de configuración con el tipo DropdownMapping aplicado
			const dropdownMapping: DropdownMapping = dropdownKeys.reduce<DropdownMapping>(
				(acc, key, index) => {
					acc[key as keyof DropdownMapping] = dropdownValuesArrays[index] || [];
					return acc;
				},
				{
					[columnType]: [],
					[columnPeriodicity]: [],
					[columnModeloRetribucion]: [],
					[columnIngresoEmpresa]: [],
					[columnReglasValoracion]: [],
					[columnVehiculoPropiedad]: []
				}
			);
		};
		reader.readAsArrayBuffer(file);
	};

	const handleAutoFill = () => {
		const bodyParams = {
			step: METRICS_WAVE_FUNNEL_PAYFIT_FEATURE.CLICK_PAYFIT_FILE_AUTOFILL
		};
		triggerMetricsWaveEvent('8daaff64-9c3b-4af2-ad2b-4655431673e3', userId, bodyParams);
		if (!sheetData || !payfitFileData) return;

		// Se asume que la primera fila es el header y las siguientes filas son datos.
		// Se recorre cada fila y se actualiza (si existe) la celda de la columna "Tipo"
		// (en este ejemplo se asume que es la columna índice 3) y se rellena con los datos
		// correspondientes del backend si el NIF coincide.
		const newSheetData = sheetData.map((row, index) => {
			if (index === 0) return row; // Encabezado sin cambios
			if (row.length >= 4) {
				const newRow = [...row];
				const nifIndex = 2; // Asumiendo que el NIF está en la columna índice 2
				const nif = row[nifIndex];
				const payfitData = payfitFileData.find((data) => data.dni === nif);
				if (payfitData) {
					let importeMensualTotal = ` ${formatArcaNumber(
						Number(payfitData.nextParticipantContribution) +
							Number(payfitData.nextPromoterContribution)
					)}`;
					newRow[3] = TipoOptions.PlanesPensiones;
					newRow[4] = PeriodicidadOptions.UnaSolaVez;
					newRow[5] = ModeloRetribucionOptions.ModeloMixto;
					newRow[6] = importeMensualTotal;
					newRow[7] = ColumnaIngresoEmpresaBoolean.No;
					newRow[8] = ` ${formatArcaNumber(Number(payfitData.nextPromoterContribution))} €`;

					// Continuar rellenando las celdas necesarias aquí
				}
				return newRow;
			}
			return row;
		});
		setSheetData(newSheetData);
		setMagicFilled(true);
	};

	const handleDownload = () => {
		if (!sheetData) return;
		// Convertir el array de arrays a una hoja de Excel
		const ws = XLSX.utils.aoa_to_sheet(sheetData);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
		// Si fileName existe, se añade el prefijo "magico_"
		const downloadName = fileName ? `magico_${fileName}` : 'archivo-magico.xlsx';
		XLSX.writeFile(wb, downloadName);
	};

	// Simplemente alterna la visualización de la tabla
	const handleVisualize = () => {
		setDataVisible(!dataVisible);
	};

	useEffect(() => {
		if (token && companyId) {
			const fetchPayfitFileData = async () => {
				try {
					const response = await getPayfitFileData(token, companyId);

					setPayfitFileData(response?.data);
				} catch (error) {
					console.error('Error al obtener Payfit file data:', error);
				}
			};

			fetchPayfitFileData();
		}
	}, [token, companyId]);

	return (
		<Modal isOpen={isOpen} onClose={handleCloseModal}>
			<ModalOverlay />
			<ModalContent maxW="1400px">
				<ModalHeader>
					Fichero con los datos de las aportaciones para elaborar tus nóminas en tu software de RRHH
				</ModalHeader>
				<ModalCloseButton onClick={() => handleCloseModal()} />
				<Box m={4}>
					<Box display="flex" justifyContent="flex-start" mt={2}>
						<Box display="flex" alignItems="center" mt={2}>
							<SoftwareSelector
								onSelect={(value: string) => setSelectedSoftware(value as SoftwareHHRR)}
							/>
						</Box>
					</Box>
					{selectedSoftware === SoftwareHHRR.OTHER && (
						<Box mt={4}>
							<Input
								placeholder="Especifica el software"
								value={otherSoftware}
								onChange={(e) => setOtherSoftware(e.target.value)}
							/>
							<Button mt={2} onClick={handleInformArca} isDisabled={otherSoftware.trim() === ''}>
								Informar a Arca
							</Button>
						</Box>
					)}
				</Box>
				{selectedSoftware === SoftwareHHRR.PAYFIT && (
					<ModalBody>
						<OrderedList>
							<ListItem>
								Descarga tu archivo plantilla siguiendo estos pasos{' '}
								<a
									href="/files/payfit_retribucion_especie_example.xlsx"
									download
									style={{ fontStyle: 'italic', textDecoration: 'underline' }}>
									(aquí tienes una plantilla de ejemplo)
								</a>
								:
								<OrderedList styleType="decimal" pl={5}>
									<ListItem>En la plataforma de Payfit, accede al menú "Retribuciones"</ListItem>
									<ListItem>
										En la sección "Retribución en especie", selecciona "Importar" y a continuación
										"Descargar una plantilla"
									</ListItem>
									<ListItem>
										Descarga el archivo y guárdalo en tu ordenador. Deberá ser un archivo .xlsx con
										la estructura de
										<a
											href="/files/payfit_retribucion_especie_example.xlsx"
											download
											style={{ fontStyle: 'italic', textDecoration: 'underline' }}>
											(la plantilla de ejemplo)
										</a>
										Si tienes alguna duda,{' '}
										<a
											href="https://drive.google.com/file/d/17zT-ei9IXZWLCi802sQQqw7gv4Mut5qJ/view?usp=drive_link"
											target="_blank"
											rel="noopener noreferrer"
											style={{ fontStyle: 'italic', textDecoration: 'underline' }}>
											mira el siguiente video tutorial
										</a>{' '}
										o contacta con
										<strong> soporte@arcadigital.app</strong>.
									</ListItem>
								</OrderedList>
							</ListItem>
							<ListItem>Impórtalo aquí y pulsa el botón "Rellenar automágicamente".</ListItem>
							<ListItem>
								Descárgalo ya relleno e impórtalo en Payfit de nuevo en el menú de "Retribuciones"
								➡️ "Importar" ➡️ "Importar retribución en especie".
							</ListItem>
							<ListItem>
								¡Disfruta del proceso <Box as="s">tedioso</Box> automágico de RRHH gracias a la
								integración Arca-Payfit!
							</ListItem>
						</OrderedList>

						<Box mt={4}>
							<Button as="label" backgroundColor={ProjectConfig.ARCA_COLORS.PINK_200} mr={4}>
								📂 Subir archivo
								<input type="file" accept=".xls,.xlsx" hidden onChange={handleFileUpload} />
							</Button>
							<Button
								backgroundColor={ProjectConfig.ARCA_COLORS.GREEN_400}
								onClick={() => {
									handleAutoFill();
								}}
								isDisabled={!sheetData || !payfitFileData}>
								🪄 Rellenar automágicamente 🪄
							</Button>
						</Box>
						{fileName && (
							<Box mt={2}>
								<strong>Archivo importado:</strong> {fileName}
								<Button
									ml={4}
									leftIcon={<CloseIcon />}
									colorScheme="red"
									variant="outline"
									size="sm"
									onClick={() =>
										window.confirm('¿Estás seguro de que quieres eliminar el archivo?') &&
										handleCloseModal(false)
									}>
									Eliminar
								</Button>
							</Box>
						)}
						<Box mt={4}>
							{sheetData && (
								<Button
									backgroundColor={ProjectConfig.ARCA_COLORS.GREEN_400}
									onClick={handleVisualize}
									isDisabled={!sheetData}
									mr={4}>
									🧐 Visualizar
								</Button>
							)}
							{magicFilled && (
								<ArcaSecondaryButton
									onClick={() => {
										const bodyParams = {
											step: METRICS_WAVE_FUNNEL_PAYFIT_FEATURE.CLICK_PAYFIT_FILE_DOWNLOAD
										};
										triggerMetricsWaveEvent(
											'8daaff64-9c3b-4af2-ad2b-4655431673e3',
											userId,
											bodyParams
										);
										handleDownload();
									}}>
									📥 Descargar archivo mágico 📥
								</ArcaSecondaryButton>
							)}
						</Box>

						<Box mt={4}>
							{!sheetData && !payfitFileData && (
								<Text>
									No se ha podido leer el archivo. Por favor, asegúrate de que el archivo lo has
									descargado de Payfit y tiene el formato correcto. Contacta con
									<strong> soporte@arcadigital.app</strong> si sigues teniendo problemas.
								</Text>
							)}
						</Box>

						{dataVisible && sheetData && sheetData.length > 0 && (
							<Box
								mt={8}
								mb={16}
								overflow="auto"
								maxH="300px"
								p={2}
								border="1px"
								borderColor="gray.300">
								<Table variant="striped" size="sm">
									<Thead>
										<Tr>
											{sheetData[0].map((cell, cellIndex) => (
												<Th key={cellIndex}>{cell}</Th>
											))}
										</Tr>
									</Thead>
									<Tbody>
										{sheetData.slice(1).map((row, rowIndex) => (
											<Tr key={rowIndex}>
												{row.map((cell, cellIndex) => (
													<Td key={cellIndex}>{cell}</Td>
												))}
											</Tr>
										))}
									</Tbody>
								</Table>
							</Box>
						)}
					</ModalBody>
				)}
			</ModalContent>
		</Modal>
	);
};

export default PayfitFileModal;
