/**
 * Función para enviar eventos a MetricsWave
 */
export function triggerMetricsWaveEvent(eventUuid: string, userId: string, params: Object = {}) {
	const NODE_ENV = process.env.NODE_ENV || 'development';

	// TODO: Actualizar condición cuando se resuelva el problema del NODE_ENV
	const condition = NODE_ENV !== 'production';
	if (condition) {
		console.log(`[EventTracker] ${eventUuid}`, params);
		return;
	}

	const bodyParams = {
		...params,
		user_id: userId
	};

	fetch(`https://metricswave.com/webhooks/${eventUuid}`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json'
		},
		body: JSON.stringify(bodyParams)
	});

	return;
}
