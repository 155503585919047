import React, { useState } from 'react';
import { Slider, SliderFilledTrack, SliderThumb, SliderTrack, Tooltip } from '@chakra-ui/react';
import ProjectConfig from '../../constants';

interface ArcaSliderProps {
	value: number;
	min?: number;
	max?: number;
	step?: number;
	onChange: (value: number) => void;
	unit?: string;
}

const ArcaSlider: React.FC<ArcaSliderProps> = ({
	value,
	min = 0,
	max = 2000,
	step = 50,
	onChange,
	unit = '€'
}) => {
	const [showTooltip, setShowTooltip] = useState(false);

	return (
		<Slider value={value} min={min} max={max} step={step} onChange={onChange}>
			<SliderTrack>
				<SliderFilledTrack bg={ProjectConfig.ARCA_COLORS.PINK_400} />
			</SliderTrack>
			<Tooltip
				hasArrow
				bg="gray.300"
				color="black"
				placement="top"
				isOpen={showTooltip}
				label={`${value} ${unit}`}>
				<SliderThumb
					boxSize="24px"
					borderRadius="full"
					bg={ProjectConfig.ARCA_COLORS.PINK_400}
					border="2px solid white"
					_hover={{
						bg: ProjectConfig.ARCA_COLORS.PINK_500,
						transform: 'translate(-0%, -50%) scale(1.5)',
						transformOrigin: 'center'
					}}
					_focus={{ boxShadow: 'outline' }}
					onMouseEnter={() => setShowTooltip(true)}
					onMouseLeave={() => setShowTooltip(false)}
					onFocus={() => setShowTooltip(true)}
					onBlur={() => setShowTooltip(false)}
					onTouchStart={() => setShowTooltip(true)}
					onTouchEnd={() => setShowTooltip(false)}
				/>
			</Tooltip>
		</Slider>
	);
};

export default ArcaSlider;
