import {
	Box,
	Button,
	Grid,
	GridItem,
	ListItem,
	SimpleGrid,
	Text,
	UnorderedList
} from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
} from 'chart.js';
import BenUserCard from '../../../components/ChakraUi/Cards/BenUserCard';
import { ArcaContext } from '../../../context/ArcaContext.context';
import { BarData, ContributionFromEnum } from '../../../api/users/interfaces/ContributionData';
import { I18nContext } from '../../../context/translations/TranslationsContext.context';
import ProjectConfig from '../../../constants';
import LoadingViewSpinner from '../../../components/ChakraUi/Spinners/LoadingViewSpinner';
import { formatArcaNumber } from '../../../utils/formatArcaNumbers';
import getAdhesionDetails from '../../../api/users/getAdhesionDetails';
import { UserAdhesionDetailsI } from '../../../api/users/interfaces/UserAdhesionDetailsI';
import GenericToast, { ToastStatusEnum } from '../../../components/ChakraUi/Toasts/GenericToast';
import getCalculatorsUserAportations, {
	PaginatedMonthContributions,
	PaginationParams
} from '../../../api/calculators/getCalculatorsUserAportations';
import SectionTitle from '../../../components/Titles/SectionTitle';
import { GenericPaginationOutput } from '../../../api/generic-dtos/genericPaginationOutput';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface UserMyBenefitTabProps {
	userName: string;
	isFreelance: boolean;
}

const UserMyBenefit: React.FC<UserMyBenefitTabProps> = ({
	userName,
	isFreelance
}: UserMyBenefitTabProps) => {
	const {
		state: { translate }
	} = useContext(I18nContext);

	const [initialLoading, setInitialLoading] = useState<boolean>(true);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [totalPages, setTotalPages] = useState<number>(1);
	const [showToast, setShowToast] = useState<boolean>(false);
	const [toastInfo, setToastInfo] = useState<any>({
		title: '',
		description: '',
		status: ''
	});

	const [userAportationsByType, setUserAportationsByType] =
		useState<GenericPaginationOutput<PaginatedMonthContributions>>();

	const [adhesionDetails, setAdhesionDetails] = useState<UserAdhesionDetailsI[]>([
		{
			userId: '',
			companyId: '',
			day: 0,
			time: 0,
			operation: '',
			productCode: 0,
			vestedRights: 0,
			numParticipations: 0,
			totalContributions: 0,
			netAssetValue: 0,
			userContributions: 0,
			companyContributions: 0,
			officeCode: 0,
			data: {
				IRBO: '',
				PIRB: '',
				USCO: 0,
				USTM: 0,
				TIPM: 0,
				MENS: '',
				NAPI: 0,
				CAAB: 0,
				PPPA: 0,
				PLIQ: 0,
				IPRE: 0,
				CAPR: 0,
				NOPE: 0,
				ACCI: 0,
				ECAJ: 0,
				USTF: 0
			}
		}
	]);

	interface Item {
		month: string;
		performanceEuros: number;
		performancePercentage: number;
		contributions: Contribution[];
	}
	interface Contribution {
		id: string;
		createdAt: string;
		updatedAt: string;
		companyId: string;
		userId: string;
		contributionAmount: number;
		typeContribution: ContributionFromEnum;
		dateContribution: string;
		statusContribution: string;
	}

	const { token } = useContext(ArcaContext);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const limitSelected = 5;
				const params: PaginationParams = { page: currentPage, limit: limitSelected };
				const contributionsRes: GenericPaginationOutput<PaginatedMonthContributions> =
					await getCalculatorsUserAportations(token, params);

				const adhesionRes = await getAdhesionDetails(token);

				setAdhesionDetails(adhesionRes.data);

				setUserAportationsByType(contributionsRes);
				setTotalPages(contributionsRes.totalPages);

				if (!adhesionRes.success || adhesionRes.statusCode === 500) {
					setToastInfo({
						title: `${translate('user.myBenefit.toastTitle')}`,
						description: `${translate('user.myBenefit.toastDescription')}`,
						status: ToastStatusEnum.ERROR
					});
					setShowToast(true);
				}
			} catch (error) {
				console.error('Error fetching data:', error);
			} finally {
				setInitialLoading(false);
			}
		};

		fetchData();
	}, [token, currentPage]);

	// Preparing the data for the dynamic chart
	const userAportationsByTypeItems = userAportationsByType?.items;

	const currentDate = new Date();
	const currentMonth = currentDate.getMonth() + 1;
	const currentYear = currentDate.getFullYear();
	const monthNames = [
		'Enero',
		'Febrero',
		'Marzo',
		'Abril',
		'Mayo',
		'Junio',
		'Julio',
		'Agosto',
		'Septiembre',
		'Octubre',
		'Noviembre',
		'Diciembre'
	];

	const allMonths = Array.from(
		{ length: currentMonth - 1 },
		(_, i) => `${currentYear}-${String(i + 1).padStart(2, '0')}`
	);

	const arrPerformanceLabels: string[] = [];
	const acumulativeParticipantData: number[] = [];
	const acumulativeCompanyData: number[] = [];
	// const planPerformance: number[] = [];

	let participantTotal = 0;
	let companyTotal = 0;
	// let performanceTotal = 0;

	const contributionsByMonth = new Map<string, Item>();
	userAportationsByTypeItems?.forEach((item) => {
		contributionsByMonth.set(item.month, item);
	});

	allMonths.forEach((month) => {
		const monthIndex = parseInt(month.split('-')[1], 10) - 1;

		const item = contributionsByMonth.get(month);
		// let monthlyPerformance = 0;

		if (item) {
			item.contributions.forEach((contribution) => {
				if (contribution.typeContribution === ContributionFromEnum.PARTICIPANT) {
					participantTotal += contribution.contributionAmount;
				} else if (contribution.typeContribution === ContributionFromEnum.COMPANY) {
					companyTotal += contribution.contributionAmount;
				}
			});

			// performanceTotal += item.performanceEuros; // Acumulando el rendimiento en euros
			// monthlyPerformance = item.performancePercentage; // Rendimiento mensual en porcentaje
		}

		arrPerformanceLabels.push(`${monthNames[monthIndex]}`);
		acumulativeParticipantData.push(participantTotal);
		acumulativeCompanyData.push(companyTotal);
		// planPerformance.push(parseFloat(performanceTotal.toFixed(2))); // Guardando el rendimiento acumulado con 2 decimales
	});
	const barData: BarData = {
		labels: arrPerformanceLabels,
		datasets: []
	};

	if (!isFreelance) {
		barData.datasets.push({
			label: translate('user.myBenefit.chartLabelCompanyContributions'),
			data: acumulativeCompanyData,
			borderColor: ProjectConfig.ARCA_COLORS.GREEN_400,
			backgroundColor: ProjectConfig.ARCA_COLORS.GREEN_500,
			hoverBackgroundColor: ProjectConfig.ARCA_COLORS.GREEN_400,
			borderWidth: 2,
			borderRadius: 16,
			barThickness: 80
		});
	}

	barData.datasets.push({
		label: translate('user.myBenefit.chartLabelMyContributions'),
		data: acumulativeParticipantData,
		borderColor: ProjectConfig.ARCA_COLORS.GREEN_600,
		backgroundColor: ProjectConfig.ARCA_COLORS.GREEN_700,
		hoverBackgroundColor: ProjectConfig.ARCA_COLORS.GREEN_600,
		borderWidth: 2,
		borderRadius: 16,
		barThickness: 80
	});
	const barOptions = {
		responsive: true,
		maintainAspectRatio: false,
		scales: {
			x: {
				stacked: true,
				ticks: {
					callback: function (value: any, index: number) {
						// Solo muestra el nombre del mes, sin el porcentaje
						const label = arrPerformanceLabels[index];
						return label.split(' ')[0]; // Retorna solo el nombre del mes
					}
				}
			},
			y: {
				stacked: true
			}
		},
		plugins: {
			legend: {
				position: 'bottom' as const
			},
			tooltip: {
				callbacks: {
					title: function (tooltipItems: any) {
						const tooltipItem = tooltipItems[0];
						return arrPerformanceLabels[tooltipItem.dataIndex]; // Muestra el mes con el porcentaje en el hover
					},
					label: function (tooltipItem: any) {
						const label = tooltipItem.dataset.label || '';
						const value = tooltipItem.raw || 0;
						return `${label}: ${value}€`; // Muestra el valor en euros
					}
				}
			},
			title: {
				display: false
			}
		}
	};

	// Preparing data for myBenefit´s cards with PPE information
	const vestedRights = adhesionDetails
		? adhesionDetails.map((item) => item.vestedRights).reduce((acc, curr) => acc + curr, 0)
		: 0;
	const totalContributions = adhesionDetails
		? adhesionDetails.map((item) => item.totalContributions).reduce((acc, curr) => acc + curr, 0)
		: 0;
	const companyContributions = adhesionDetails
		? adhesionDetails.map((item) => item.companyContributions).reduce((acc, curr) => acc + curr, 0)
		: 0;
	const userContributions = adhesionDetails
		? adhesionDetails.map((item) => item.userContributions).reduce((acc, curr) => acc + curr, 0)
		: 0;

	const totalSaved = vestedRights > 0 ? vestedRights / 100 : 0;

	// Calcular totalPerformanceGenerated, asegurando que no haya divisiones por cero
	const totalPerformanceGenerated =
		vestedRights > 0 && totalContributions > 0 ? vestedRights / 100 - totalContributions / 100 : 0;

	const totalPerformanceGeneratedPercentage = (totalPerformanceGenerated / totalSaved) * 100;

	const plantStatusList = (
		<UnorderedList listStyleType="none" ml="-1px" fontSize="18px">
			{/* Solo mostramos las contribuciones de la empresa si no es freelancer */}
			{!isFreelance && (
				<ListItem>
					<Text as="span" fontSize="16px" fontFamily={ProjectConfig.FONTS.INTER} fontWeight="400">
						{`${translate('user.myBenefit.totalCompanyContributions')}`}
					</Text>
					<Text as="span" fontSize="32px">
						{formatArcaNumber(companyContributions / 10)}€
					</Text>
				</ListItem>
			)}
			<ListItem>
				<Text as="span" fontSize="16px" fontFamily={ProjectConfig.FONTS.INTER} fontWeight="400">
					{`${translate('user.myBenefit.totalParticipantContributions')}`}
				</Text>
				<Text as="span" fontSize="30px">
					{formatArcaNumber(userContributions / 10)}€
				</Text>
			</ListItem>
		</UnorderedList>
	);

	return (
		<div>
			{initialLoading ? (
				<Box h="80dvh" display="flex" justifyContent="center" alignItems="center">
					<LoadingViewSpinner />
				</Box>
			) : (
				<div>
					{showToast && (
						<GenericToast
							title={toastInfo?.title}
							description={toastInfo?.description}
							status={toastInfo?.status}
							showToast={showToast}
							duration={6000}
						/>
					)}

					<Box>
						<Text fontSize="48px" fontWeight="bold" p="0px 0px 0px 0px">
							{translate('admin.home.hello')}
							<Text as="span" color={ProjectConfig.ARCA_COLORS.GREEN_500}>
								{userName}
							</Text>
						</Text>
					</Box>

					<SectionTitle text={translate('user.myBenefit.companyData')} />

					<Grid
						templateAreas={`"slo00 slo00 slo00 slo00 slo00"
										"slo07 slo07 slo07 slo07 slo07"`}
						gridTemplateRows={'minmax(200px,auto) 400px'}
						gridTemplateColumns={'1fr 1fr 1fr 1fr 1fr'}
						h="minmax(90vh, auto)"
						color="blackAlpha.700">
						<GridItem area={'slo00'}>
							<SimpleGrid spacing="16px" columns={5} minChildWidth="200px" h="90%">
								<BenUserCard
									heading={translate('user.myBenefit.totalContributions')}
									cardBody={`${formatArcaNumber(totalSaved)}€`}
									infoIconVisible={true}
									infoIconText={translate('user.myBenefit.totalContributionsIconText')}
								/>
								<GridItem colSpan={2}>
									<BenUserCard
										heading={`${translate('user.myBenefit.contributionsInformation')} `}
										cardBody={plantStatusList}
										infoIconVisible={true}
										infoIconText={translate('user.myBenefit.totalSavingsIconText')}
										isClickable={true}
										path="/dashboard/my-contributions"
									/>
								</GridItem>
								<BenUserCard
									heading={`${translate('user.myBenefit.totalPerformanceGenerated')}`}
									cardBody={
										<>
											<Text as="span" fontSize="24px">
												{`${formatArcaNumber(totalPerformanceGeneratedPercentage)}%`}
											</Text>
											<br />
											{`${formatArcaNumber(totalPerformanceGenerated)}€`}
										</>
									}
									infoIconVisible={true}
									infoIconText={translate('user.myBenefit.updatedPerformanceIconText')}
								/>
							</SimpleGrid>
						</GridItem>

						<GridItem area={'slo07'}>
							<SectionTitle text={translate('user.myBenefit.tableTitle')} />

							<Box
								w="100%"
								h="100%"
								backgroundColor={ProjectConfig.ARCA_COLORS.WHITE}
								borderRadius="12px"
								textAlign="end"
								p="16px 24px 64px 24px">
								<Box textAlign="end">
									<Button
										borderRadius="20px"
										borderColor={ProjectConfig.ARCA_COLORS.GREEN_600}
										color={ProjectConfig.ARCA_COLORS.GREEN_600}
										variant="outline"
										fontWeight={'bold'}
										width={100}>
										{2024}
									</Button>
								</Box>

								<Bar options={barOptions} data={barData} />
							</Box>
						</GridItem>
					</Grid>
				</div>
			)}
		</div>
	);
};

export default UserMyBenefit;
