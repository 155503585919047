import React, { useContext, useEffect, useState } from 'react';
import {
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	Box,
	Tfoot,
	Skeleton,
	useDisclosure,
	Stack
} from '@chakra-ui/react';
import { ArcaContext } from '../../../context/ArcaContext.context';
import inviteEmployees from '../../../api/companies/inviteEmployees';
import GenericToast, { ToastStatusEnum } from '../../../components/ChakraUi/Toasts/GenericToast';
import { I18nContext } from '../../../context/translations/TranslationsContext.context';
import ProjectConfig from '../../../constants';
import LoadingViewSpinner from '../../../components/ChakraUi/Spinners/LoadingViewSpinner';
import Pagination from '../../../components/ChakraUi/Pagination';
import findParticipantsContributionData from '../../../api/calculators/findParticipantsContributionData';
import GenericModal from '../../../components/ChakraUi/Modals/GenericModal';
import { formatArcaNumber } from '../../../utils/formatArcaNumbers';
import SectionTitle from '../../../components/Titles/SectionTitle';
import ArcaPrimaryButton from '../../../components/Buttons/ArcaPrimaryButton';
import exportStaffTable from '../../../api/emails/exportStaffTable';

const PlantillaTab: React.FC = () => {
	const {
		state: { translate }
	} = useContext(I18nContext);

	const [initialLoading, setInitialLoading] = useState<boolean>(true);
	const [isInviteLoading, setIsInviteLoading] = useState<boolean>(false);
	const [isExportLoading, setIsExportLoading] = useState<boolean>(false);
	const [usersInputCalculations, setUsersInputCalculations] = useState<any[]>([]);
	const [showToast, setShowToast] = useState<boolean>(false);
	const [toastInfo, setToastInfo] = useState<any>({
		title: '',
		description: '',
		status: ''
	});
	const { isOpen: isOpenGeneric, onOpen: onOpenGeneric, onClose: onCloseGeneric } = useDisclosure();

	const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);

	const { token, companyId } = useContext(ArcaContext);

	useEffect(() => {
		const limitSelected = 10;

		findParticipantsContributionData(token, currentPage, limitSelected).then((res) => {
			setUsersInputCalculations(res.data.items);

			setTotalPages(res.data.totalPages);
			setIsPageLoading(false);
			setInitialLoading(false);
		});
	}, [token, companyId, currentPage]);

	const inviteEmployeesTitle = translate('admin.staff.inviteEmployeesTitle');
	const inviteEmployeesSubtitle = translate('admin.staff.inviteEmployeesSubtitle');

	const handleOpenGenericModal = () => {
		onOpenGeneric();
	};

	const handleClickInviteEmployees = async (emails?: string) => {
		const emailsToInvite = emails?.split(',').map((email) => email.trim()) || [];

		try {
			setIsInviteLoading(true);
			const inviteEmployeesResponse = await inviteEmployees(token, companyId, emailsToInvite);

			if (!inviteEmployeesResponse.success) {
				setToastInfo({
					title: `${translate('admin.staff.inviteEmployeesToastTitle')}`,
					description: `${translate('admin.staff.inviteEmployeesToastDescription')}`,
					status: ToastStatusEnum.ERROR
				});
				setShowToast(true);
			} else {
				const totalEmployeesInvited = inviteEmployeesResponse?.data?.totalEmailsSended;

				const intitationTextSingularOrPlural =
					totalEmployeesInvited === 1
						? `${translate('admin.staff.inviteEmployeesToastSingle')}`
						: `${translate('admin.staff.inviteEmployeesToastPlural')}`;

				const sendedTextSingularOrPlural =
					totalEmployeesInvited === 1
						? `${translate('admin.staff.inviteEmployeesSendedSingle')}`
						: `${translate('admin.staff.inviteEmployeesSendedPlural')}`;

				const fullDescription =
					totalEmployeesInvited > 0
						? `${totalEmployeesInvited} ${intitationTextSingularOrPlural} ${sendedTextSingularOrPlural}`
						: `${translate('admin.staff.employeesAlreadyInvited')}`;

				setToastInfo({
					title: `${translate('admin.staff.inviteEmployeesCompleted')}`,
					description: fullDescription,
					status: ToastStatusEnum.SUCCESS
				});
				setShowToast(true);
			}
		} finally {
			setIsInviteLoading(false);
		}
	};

	const handleExportStaffTable = async () => {
		try {
			setIsExportLoading(true);
			const response = await exportStaffTable(token);

			if (response.success) {
				setToastInfo({
					title: translate('admin.staff.exportDataSuccessTitle'),
					description: translate('admin.staff.exportDataSuccessDescription'),
					status: ToastStatusEnum.SUCCESS
				});
			} else {
				setToastInfo({
					title: translate('admin.staff.exportDataErrorTitle'),
					description: translate('admin.staff.exportDataErrorDescription'),
					status: ToastStatusEnum.ERROR
				});
			}
			setShowToast(true);
		} catch (error) {
			setToastInfo({
				title: translate('admin.staff.exportDataErrorTitle'),
				description: translate('admin.staff.exportDataErrorDescription'),
				status: ToastStatusEnum.ERROR
			});
			setShowToast(true);
		} finally {
			setIsExportLoading(false);
		}
	};

	const handlePageChange = (page: number) => {
		setIsPageLoading(true);
		setCurrentPage(page);
	};

	return (
		<div>
			{initialLoading ? (
				<Box h="80dvh" display="flex" justifyContent="center" alignItems="center">
					<LoadingViewSpinner />
				</Box>
			) : (
				<Box mt="16px">
					<Box display="flex" justifyContent="space-between">
						<SectionTitle text="Listado Partícipes" />

						<Stack direction="row">
							<ArcaPrimaryButton isLoading={isInviteLoading} onClick={handleOpenGenericModal}>
								{translate('admin.staff.inviteAllEmployees')}
							</ArcaPrimaryButton>
							<Box mt="6px" height="48px" borderLeft="1px solid black" borderRadius="2px" />
							<ArcaPrimaryButton isLoading={isExportLoading} onClick={handleExportStaffTable}>
								{translate('admin.staff.exportData')}
							</ArcaPrimaryButton>
						</Stack>
					</Box>

					<Box
						p="16px 25px 0px 25px"
						backgroundColor={ProjectConfig.ARCA_COLORS.WHITE}
						borderRadius="16px">
						<Table width="100%">
							<Thead>
								<Tr>
									<Th
										color={ProjectConfig.ARCA_COLORS.GREEN_800}
										borderBottomWidth="2px"
										borderColor={ProjectConfig.ARCA_COLORS.GREEN_400}
										textAlign="center"
										maxW="200px"
										justifySelf="flex-end"
										fontSize="10px">
										{translate('admin.staff.employeeEmail')}
									</Th>
									<Th
										color={ProjectConfig.ARCA_COLORS.GREEN_800}
										borderBottomWidth="2px"
										borderColor={ProjectConfig.ARCA_COLORS.GREEN_400}
										textAlign="center"
										w="80px"
										fontSize="10px">
										{translate('admin.staff.userAdherentAndContributing')}
									</Th>
									<Th
										color={ProjectConfig.ARCA_COLORS.GREEN_800}
										borderBottomWidth="2px"
										borderColor={ProjectConfig.ARCA_COLORS.GREEN_400}
										textAlign="center"
										w="80px"
										fontSize="10px">
										{translate('admin.staff.totalAported')}
									</Th>
									<Th
										color={ProjectConfig.ARCA_COLORS.GREEN_800}
										borderBottomWidth="2px"
										borderColor={ProjectConfig.ARCA_COLORS.GREEN_400}
										textAlign="center"
										w="80px"
										fontSize="10px">
										{translate('admin.staff.totalContributed')}
									</Th>
									<Th
										color={ProjectConfig.ARCA_COLORS.GREEN_800}
										borderBottomWidth="2px"
										borderColor={ProjectConfig.ARCA_COLORS.GREEN_400}
										textAlign="center"
										w="80px"
										fontSize="10px">
										{translate('admin.staff.generatedEconomicalRights')}
									</Th>
									<Th
										color={ProjectConfig.ARCA_COLORS.GREEN_800}
										borderBottomWidth="2px"
										borderColor={ProjectConfig.ARCA_COLORS.GREEN_400}
										textAlign="center"
										w="80px"
										fontSize="10px">
										{translate('admin.staff.generatedSalaryByReduction')}
									</Th>
									<Th
										color={ProjectConfig.ARCA_COLORS.GREEN_800}
										borderBottomWidth="2px"
										borderColor={ProjectConfig.ARCA_COLORS.GREEN_400}
										textAlign="center"
										w="80px"
										fontSize="10px">
										{translate('admin.staff.nextParticipantContribution')}
									</Th>
									<Th
										color={ProjectConfig.ARCA_COLORS.GREEN_800}
										borderBottomWidth="2px"
										borderColor={ProjectConfig.ARCA_COLORS.GREEN_400}
										textAlign="center"
										w="80px"
										fontSize="10px">
										{translate('admin.staff.nextPromoterContribution')}
									</Th>
									<Th
										color={ProjectConfig.ARCA_COLORS.GREEN_800}
										borderBottomWidth="2px"
										borderColor={ProjectConfig.ARCA_COLORS.GREEN_400}
										textAlign="center"
										w="80px"
										fontSize="10px">
										{translate('admin.staff.annualSalary')}
									</Th>
								</Tr>
							</Thead>
							<Tbody>
								{/* Renderizamos las filas de usuarios */}
								{isPageLoading
									? Array.from({ length: 5 }).map((_, index) => (
											<Tr key={index}>
												<Td
													py="10px"
													borderColor="gray.300"
													textAlign="center"
													fontSize="14px"
													px="2">
													<Skeleton fadeDuration={7} height="30px" />
												</Td>
												<Td
													py="10px"
													borderColor="gray.300"
													textAlign="center"
													fontSize="14px"
													px="2">
													<Skeleton fadeDuration={7} height="30px" />
												</Td>
												<Td
													py="10px"
													borderColor="gray.300"
													textAlign="center"
													fontSize="14px"
													px="2">
													<Skeleton fadeDuration={7} height="30px" />
												</Td>
												<Td
													py="10px"
													borderColor="gray.300"
													textAlign="center"
													fontSize="14px"
													px="2">
													<Skeleton fadeDuration={7} height="30px" />
												</Td>
												<Td
													py="10px"
													borderColor="gray.300"
													textAlign="center"
													fontSize="14px"
													px="2">
													<Skeleton fadeDuration={7} height="30px" />
												</Td>
												<Td
													py="10px"
													borderColor="gray.300"
													textAlign="center"
													fontSize="14px"
													px="2">
													<Skeleton fadeDuration={7} height="30px" />
												</Td>
												<Td
													py="10px"
													borderColor="gray.300"
													textAlign="center"
													fontSize="14px"
													px="2">
													<Skeleton fadeDuration={7} height="30px" />
												</Td>
												<Td
													py="10px"
													borderColor="gray.300"
													textAlign="center"
													fontSize="14px"
													px="2">
													<Skeleton fadeDuration={7} height="30px" />
												</Td>
												<Td
													py="10px"
													borderColor="gray.300"
													textAlign="center"
													fontSize="14px"
													px="2">
													<Skeleton fadeDuration={7} height="30px" />
												</Td>
												<Td
													py="10px"
													borderColor="gray.300"
													textAlign="center"
													fontSize="14px"
													px="2">
													<Skeleton fadeDuration={7} height="30px" />
												</Td>
											</Tr>
										))
									: usersInputCalculations.map((userInputCalculation, index) => (
											<Tr key={index}>
												<Td borderColor="gray.300" textAlign="center" fontSize="14px" px="2">
													{userInputCalculation?.user?.email?.length > 24
														? `${userInputCalculation.user.email.substring(0, 24)}...`
														: userInputCalculation?.user?.email}
												</Td>
												<Td borderColor="gray.300" textAlign="center" fontSize="14px" px="2">
													{userInputCalculation?.user?.status === 'active' &&
													userInputCalculation?.user?.completedOnboarding &&
													userInputCalculation?.user?.completedProfile
														? '✅'
														: '❌'}
												</Td>
												<Td borderColor="gray.300" textAlign="center" fontSize="14px" px="2">
													{`${formatArcaNumber(userInputCalculation.totalContributionsByParticipant)}  €`}
												</Td>
												<Td borderColor="gray.300" textAlign="center" fontSize="14px" px="2">
													{`${formatArcaNumber(userInputCalculation.totalContributionsByCompany)}  €`}
												</Td>
												<Td borderColor="gray.300" textAlign="center" fontSize="14px" px="2">
													{`${formatArcaNumber(userInputCalculation.totalEconomicsRightsGenerated)}  €`}
												</Td>
												<Td borderColor="gray.300" textAlign="center" fontSize="14px" px="2">
													{`${formatArcaNumber(userInputCalculation.savingsByContingencesReduction)}  €`}
												</Td>
												<Td borderColor="gray.300" textAlign="center" fontSize="14px" px="2">
													{userInputCalculation?.user?.status === 'active' &&
													userInputCalculation?.user?.completedOnboarding &&
													userInputCalculation?.user?.completedProfile
														? `${formatArcaNumber(userInputCalculation?.nextParticipantContribution)} €`
														: '-'}
												</Td>
												<Td borderColor="gray.300" textAlign="center" fontSize="14px" px="2">
													{userInputCalculation?.user?.status === 'active' &&
													userInputCalculation?.user?.completedOnboarding &&
													userInputCalculation?.user?.completedProfile
														? `${formatArcaNumber(Number(userInputCalculation?.nextPromoterContribution))} €`
														: '-'}
												</Td>
												<Td borderColor="gray.300" textAlign="center" fontSize="14px" px="2">
													{userInputCalculation?.user?.grossAnnualSalary
														? `${formatArcaNumber(userInputCalculation?.user?.grossAnnualSalary)} €`
														: '-'}
												</Td>
											</Tr>
										))}
							</Tbody>
							<Tfoot>
								<Tr>
									<Td colSpan={8} textAlign="center" py="20px">
										<Pagination
											currentPage={currentPage}
											totalPages={totalPages}
											onPageChange={handlePageChange}
										/>
									</Td>
								</Tr>
							</Tfoot>
						</Table>
					</Box>
				</Box>
			)}
			{showToast && (
				<GenericToast
					title={toastInfo?.title}
					description={toastInfo?.description}
					status={toastInfo?.status}
					showToast={showToast}
					duration={4000}
				/>
			)}
			<GenericModal
				isOpen={isOpenGeneric}
				onClose={onCloseGeneric}
				message={''}
				isError={false}
				onAction={handleClickInviteEmployees}
				inputString={true}
				modalTitle={inviteEmployeesTitle}
				modalSubtitle={inviteEmployeesSubtitle}
				closeModalButton={true}
				maxWidth="80%"
			/>
		</div>
	);
};

export default PlantillaTab;
