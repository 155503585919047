const userCompleteOnboarding = async (
	token: string,
	pensionContribution: number | string,
	gender: string,
	name: string,
	firstSurname: string,
	lastSurname: string,
	nif: string,
	email: string,
	password: string,
	birthdate: string
) => {
	const response = await fetch(`${process.env.REACT_APP_BASE_API}/users/complete-onboarding`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		},
		body: JSON.stringify({
			pensionContribution: Number(pensionContribution),
			password,
			gender,
			name,
			firstSurname,
			lastSurname,
			nif,
			email,
			birthdate
		})
	});

	return response.json();
};

export default userCompleteOnboarding;
