import React from 'react';
import { Box, Text, Image } from '@chakra-ui/react';
import ProjectConfig from '../constants';

const Maintenance: React.FC = () => {
	return (
		<Box
			display="flex"
			flexDirection="column"
			alignItems="center"
			justifyContent="center"
			height="100vh"
			backgroundColor={ProjectConfig.ARCA_COLORS.LIGHT_GREY_03}>
			<Image src="/imgs/LOGO_VERDE_2.svg" alt="Logo Arca" boxSize="150px" mb={4} />
			<Text fontSize="2xl" fontWeight="bold" mb={2}>
				Estamos en mantenimiento
			</Text>
			<Text fontSize="lg" mb={4}>
				Pronto estaremos de vuelta.
			</Text>
			<Text fontSize="md">
				Para cualquier urgencia, pueden contactar con{' '}
				<a href="mailto:soporte@arcadigital.app">soporte@arcadigital.app</a>
			</Text>
		</Box>
	);
};

export default Maintenance;
