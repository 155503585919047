import { Box, Text } from '@chakra-ui/react';

const ConfidenceBoosterLiteComponent = ({}) => {
	return (
		<Box display="flex" flexDirection="column" alignItems="center" mt={4} mb={4}>
			<Text>
				Tu plan de pensiones está gestionado por Arca y Caser Pensiones, asesorado por Indexa
				Capital y depositado en Cecabank
			</Text>
		</Box>
	);
};

export default ConfidenceBoosterLiteComponent;
