import React from 'react';
import { Box, Image, Text } from '@chakra-ui/react';
import ProjectConfig from '../../../constants';

interface SimulatorCardProps {
	onClick: () => void;
	imageSrc: string;
	text: string;
}

const SimulatorCard: React.FC<SimulatorCardProps> = ({ onClick, imageSrc, text }) => {
	return (
		<Box
			width="320px"
			height="320px"
			bg="gray.100"
			borderRadius="md"
			boxShadow="md"
			display="flex"
			flexDirection="column"
			alignItems="center"
			justifyContent="center"
			cursor="pointer"
			onClick={onClick}
			m={4}
			p={8}
			border="4px solid"
			borderColor={ProjectConfig.ARCA_COLORS.PINK_300}
			transition="all 0.2s"
			_hover={{
				borderColor: ProjectConfig.ARCA_COLORS.PINK_600,
				boxShadow: 'lg',
				transform: 'scale(1.05)'
			}}>
			<Image
				src={imageSrc}
				alt="Imagen del simulador"
				height="200px"
				width="200px"
				objectFit="cover"
			/>
			<Text fontWeight="bold" fontSize="2xl" mt="4">
				{text}
			</Text>
		</Box>
	);
};

export default SimulatorCard;
