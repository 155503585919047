import { useNavigate, useLocation } from 'react-router';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import {
	Flex,
	Box,
	VStack,
	Button,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Avatar,
	Text,
	useMediaQuery,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton
} from '@chakra-ui/react';
import { GrUserAdmin } from 'react-icons/gr';
import { FaRegUser, FaChevronDown } from 'react-icons/fa';
import {
	TbChevronLeftPipe,
	TbChevronRightPipe,
	TbFileInvoice,
	TbLogout,
	TbPigMoney
} from 'react-icons/tb';
import { GoHome } from 'react-icons/go';
import { PiUsersFourLight } from 'react-icons/pi';
import { HiOutlineDocumentDuplicate } from 'react-icons/hi2';
import { MdDisplaySettings } from 'react-icons/md';
import { AiOutlineMessage } from 'react-icons/ai';

// Tabs del usuario
import UserMyBenefit from './DashboardTabs/User/MiBeneficio';
import UserConfigurarBeneficioTab from './DashboardTabs/User/ConfigurarBeneficio';
import PlanEmpleo from './DashboardTabs/User/PlanEmpleo';
import UserAjustesTab from './DashboardTabs/User/AjustesUser';

// Tabs del administrador
import AdminInicioTab from './DashboardTabs/Admin/InicioAdmin';
import PlantillaTab from './DashboardTabs/Admin/Plantilla';
import AdminConfigurarBeneficioTab from './DashboardTabs/Admin/ConfigurarBeneficio';
import AdminBilling from './DashboardTabs/Admin/AdminBilling';

import { ArcaContext } from '../context/ArcaContext.context';
import { I18nContext } from '../context/translations/TranslationsContext.context';
import ProjectConfig from '../constants';
import getCurrentUser from '../api/users/getCurrentUser';
import EMPTY_CURRENT_USER, { CurrentUserI } from '../api/users/interfaces/Users ';
import AdminContribuciones from './DashboardTabs/Admin/AdminContribuciones';
import { clearContextAndLocalStorage } from '../utils/clearLocalStorageAndContext';
import syncAllMovements from '../api/users/syncAllMovements';
import dayjs from 'dayjs';

interface Tab {
	key: string;
	label: string;
	icon: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
	component: ReactNode;
	route: string;
}

interface DashboardProps {}

const Dashboard: React.FC<DashboardProps> = () => {
	const { token } = useContext(ArcaContext);
	const navigate = useNavigate();
	const location = useLocation();
	const { userAccountType, language } = useContext(ArcaContext);

	const {
		state: { translate }
	} = useContext(I18nContext);

	const myBenefit = `${translate('user.dashboard.myBenefit')}`;
	const userMyContributions = `${translate('user.dashboard.myContributions')}`;
	const adminMyContributions = `${translate('admin.dashboard.myContributions')}`;
	const employmentPlan = `${translate('user.dashboard.employmentPlan')}`;
	const userSettings = `${translate('user.dashboard.userSettings')}`;
	const home = `${translate('admin.dashboard.home')}`;
	const staff = `${translate('admin.dashboard.staff')}`;
	const setBenefit = `${translate('admin.dashboard.setBenefit')}`;
	const adminSettings = `${translate('admin.dashboard.adminSettings')}`;
	const billing = `${translate('admin.dashboard.billing')}`;

	// Defino los tabs por defecto para el usuario y el administrador
	const USER_DEFAULT_TAB = myBenefit;
	const ADMIN_DEFAULT_TAB = home;

	const [isSmallScreen] = useMediaQuery('(max-width: 768px)');

	const [isMenuOpen, setIsMenuOpen] = useState(true);

	const [activeTab, setActiveTab] = useState(
		userAccountType === 'BOTH' || userAccountType === 'ADMIN' ? ADMIN_DEFAULT_TAB : USER_DEFAULT_TAB
	);
	const [viewType, setViewType] = useState<string>(
		userAccountType === 'BOTH' || userAccountType === 'ADMIN' ? 'ADMIN' : 'USER'
	);

	const [isChatOpen, setIsChatOpen] = useState(false);

	const [currentUser, setCurrentUser] = useState<CurrentUserI>(EMPTY_CURRENT_USER);
	const [onboardingDone, setOnboardingDone] = useState<boolean>(true);

	const [isFreelance, setIsFreelance] = useState<boolean>(false);

	useEffect(() => {
		const initializeMenuState = () => {
			setIsMenuOpen(!isSmallScreen);
		};

		initializeMenuState();
	}, [isSmallScreen]);

	useEffect(() => {
		userAccountType === 'USER'
			? navigate(`/dashboard/${userTabs[0].route}`)
			: navigate(`/dashboard/${adminTabs[0].route}`);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const getCurrentUserAsync = async () => {
			const currentUser = await getCurrentUser(token);
			const currentUserData = currentUser.data as CurrentUserI;
			setCurrentUser(currentUserData);

			setIsFreelance(currentUserData?.user?.isFreelance);

			const userOnboard = currentUser.data.user.completedOnboarding;
			setOnboardingDone(userOnboard);

			if (!userOnboard) {
				navigate('/onboarding-user');
			}

			const user = currentUserData?.user;
			const date = dayjs(user.lastMovementsUpdate);
			const userHasSynchronisedMovementsToday = date.isSame(dayjs(), 'day');

			if (!userHasSynchronisedMovementsToday) {
				await syncAllMovements(token);
			}

			const sameViewType = viewType;
			setViewType(sameViewType);

			const newActiveTab = sameViewType === 'ADMIN' ? ADMIN_DEFAULT_TAB : USER_DEFAULT_TAB;
			setActiveTab(newActiveTab);
		};

		getCurrentUserAsync();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [language, userAccountType, viewType, token]);

	const userTabs: Tab[] = [
		{
			key: myBenefit,
			label: myBenefit,
			icon: <GoHome />,
			component: (
				<UserMyBenefit userName={currentUser?.user.name || ''} isFreelance={isFreelance} />
			),
			route: 'my-benefit'
		},
		{
			key: userMyContributions,
			label: userMyContributions,
			icon: <TbPigMoney />,
			component: <UserConfigurarBeneficioTab isFreelance={isFreelance} />,
			route: 'my-contributions'
		},
		{
			key: employmentPlan,
			label: employmentPlan,
			icon: <HiOutlineDocumentDuplicate />,
			component: <PlanEmpleo isFreelance={isFreelance} />,
			route: 'employment-pension-plan'
		},
		{
			key: userSettings,
			label: userSettings,
			icon: <MdDisplaySettings />,
			component: <UserAjustesTab />,
			route: 'settings'
		}
	];

	const adminTabs: Tab[] = [
		{
			key: home,
			label: home,
			icon: <GoHome />,
			component: (
				<AdminInicioTab
					userName={currentUser?.user.name || ''}
					representsCompany={currentUser?.user.representsCompany}
					representsWorkers={currentUser?.user.representsParticipant}
				/>
			),
			route: 'home'
		},
		{
			key: staff,
			label: staff,
			icon: <PiUsersFourLight />,
			component: <PlantillaTab />,
			route: 'staff'
		},
		{
			key: adminMyContributions,
			label: adminMyContributions,
			icon: <TbPigMoney />,
			component: <AdminContribuciones />,
			route: 'contributions'
		},
		{
			key: setBenefit,
			label: setBenefit,
			icon: <HiOutlineDocumentDuplicate />,
			component: <AdminConfigurarBeneficioTab />,
			route: 'set-benefit'
		},
		{
			key: billing,
			label: billing,
			icon: <TbFileInvoice />,
			component: <AdminBilling />,
			route: 'billing'
		},
		{
			key: adminSettings,
			label: adminSettings,
			icon: <MdDisplaySettings />,
			component: <UserAjustesTab />,
			route: 'settings'
		}
	];

	const currentTabs = viewType === 'USER' ? userTabs : adminTabs;

	const handleLogout = () => {
		clearContextAndLocalStorage();
		navigate('/');
	};

	const handleChatClick = () => {
		setIsChatOpen((isChatOpen) => !isChatOpen);
	};

	const setActiveTabAndNavigate = (tab: Tab) => {
		setActiveTab(tab.key);
		navigate(`/dashboard/${tab.route}`);
	};

	const findTabByRoute = (route: string): Tab | undefined => {
		const tabs = viewType === 'USER' ? userTabs : adminTabs;
		return tabs.find((tab) => tab.route === route);
	};

	useEffect(() => {
		const path = location.pathname.split('/').pop() || '';
		const tab = findTabByRoute(path);
		if (tab) {
			setActiveTab(tab.key);
		}
	}, [location, viewType]);

	const getActiveComponent = (activeTab: string): ReactNode | null => {
		const tabs = viewType === 'USER' ? userTabs : adminTabs;
		const tab = tabs.find((tab) => tab.key === activeTab);
		return tab ? tab.component : null;
	};

	return (
		<Flex h="100%" objectFit="contain">
			<VStack
				zIndex="2"
				textAlign="center"
				justifyContent="space-between"
				alignItems="center"
				width={isMenuOpen ? '330px' : '100px'}
				height="100vh"
				position="sticky"
				top={0}
				p={4}
				bg={ProjectConfig.ARCA_COLORS.WHITE}>
				<Box>
					{/* OPEN/CLOSE MENU */}
					<Button
						width="14%"
						onClick={() => setIsMenuOpen(!isMenuOpen)}
						bgColor={ProjectConfig.ARCA_COLORS.PINK_500}
						_hover={{ color: ProjectConfig.ARCA_COLORS.PINK_100 }}
						fontSize={28}>
						<Box fontSize="28px" fontWeight="bold">
							{isMenuOpen ? <TbChevronLeftPipe /> : <TbChevronRightPipe />}
						</Box>
					</Button>

					{/* OPEN/CLOSE MENU */}

					{/* LOGO EMPRESA PROMOTORA*/}
					{/* <Box mt={1}>
						<Box display="flex" justifyContent="center">
							<Img src="" />
						</Box>
					</Box> */}

					{/* LOGO EMPRESA PROMOTORA*/}

					{/* AVATAR */}

					<Box mt={1} display="flex" justifyContent="center">
						{isMenuOpen ? (
							<Box display="flex" alignItems="center" height="72px">
								{' '}
								{/* Establece la altura fija aquí */}
								<Avatar
									size="md"
									name={currentUser?.user.name || ''}
									src="/imgs/user-profile.png"
								/>
								<Box ml="4">
									<Text fontWeight="bold" fontSize={18}>
										{currentUser?.user.name || ''} {currentUser?.user.firstSurname || ''}
									</Text>
									<Text>
										{currentUser?.user.email?.substring(0, 30) +
											(currentUser?.user.email?.length > 30 ? '...' : '') || ''}
									</Text>
								</Box>
							</Box>
						) : (
							<Box display="flex" alignItems="center" height="72px">
								{' '}
								{/* Mantén la misma altura fija aquí */}
								<Avatar
									size="md"
									name={currentUser?.user.name || ''}
									src="/imgs/user-profile.png"
								/>
							</Box>
						)}
					</Box>

					{/* AVATAR */}

					{/* ROL SELECTOR */}

					{userAccountType === 'BOTH' ? (
						<Box marginTop={6} borderRadius="30px" border="1px solid" overflow="hidden">
							<Box display="flex" justifyContent="center" w="100%">
								<Menu>
									<MenuButton
										as={Button}
										w="100%"
										bgColor={ProjectConfig.ARCA_COLORS.WHITE}
										fontWeight=""
										fontSize="18px"
										borderRadius="20px"
										rightIcon={isMenuOpen ? <FaChevronDown /> : undefined}
										m={0}
										pl={isMenuOpen ? '50px' : '10px'}
										pr={isMenuOpen ? '50px' : '0px'}
										textAlign="center">
										{viewType === 'USER' ? (
											<Box
												display="flex"
												justifyContent="space-between"
												alignItems="center"
												margin="12px">
												<Box fontSize="21px" pr="10px">
													<FaRegUser />
												</Box>

												{isMenuOpen &&
													(isFreelance
														? translate('general.avatar.freelanceView')
														: translate('general.avatar.userView'))}
											</Box>
										) : (
											<Box
												display="flex"
												justifyContent="space-between"
												alignItems="center"
												margin="12px">
												<Box fontSize="23px">
													<GrUserAdmin />
												</Box>
												{isMenuOpen && translate('general.avatar.adminView')}
											</Box>
										)}
									</MenuButton>
									<MenuList borderRadius="25px">
										<MenuItem
											p="10px 0px 10px 100px"
											bg={viewType === 'ADMIN' ? ProjectConfig.ARCA_COLORS.PINK_100 : 'white'}
											onClick={() => {
												setViewType('ADMIN');
												setActiveTab(home);
												navigate(`/dashboard/${adminTabs[0].route}`);
											}}>
											<Box fontSize="24px">
												<GrUserAdmin />
											</Box>
											&nbsp;&nbsp; {translate('general.avatar.admin')}
										</MenuItem>
										<MenuItem
											p="10px 95px 10px 90px"
											bg={viewType === 'USER' ? ProjectConfig.ARCA_COLORS.PINK_100 : 'white'}
											onClick={() => {
												setViewType('USER');
												setActiveTab(myBenefit);
												navigate(`/dashboard/${userTabs[0].route}`);
											}}>
											<Box fontSize="22px">
												<FaRegUser />
											</Box>
											&nbsp;&nbsp; {translate('general.avatar.user')}
										</MenuItem>
									</MenuList>
								</Menu>
							</Box>
						</Box>
					) : (
						<Box marginTop={6} borderRadius="30px" border="1px solid" overflow="hidden">
							<Box display="flex" justifyContent="center" w="100%">
								<Button
									w="100%"
									bgColor={ProjectConfig.ARCA_COLORS.WHITE}
									fontWeight=""
									fontSize="18px"
									borderRadius="20px"
									m={0}
									pl="10px"
									pr="0px"
									textAlign="center"
									_hover={{ color: ProjectConfig.ARCA_COLORS.PINK_100 }}>
									<Box
										display="flex"
										justifyContent="space-between"
										alignItems="center"
										margin="12px">
										<Box fontSize="21px" pr="10px">
											<FaRegUser />
										</Box>
										{isFreelance
											? translate('general.avatar.freelanceView')
											: translate('general.avatar.userView')}
									</Box>
								</Button>
							</Box>
						</Box>
					)}

					{/* ROL SELECTOR */}
				</Box>

				{/* TABS */}

				<Box>
					{currentTabs.map((tab) => (
						<Button
							fontSize="20px"
							justifyContent="start"
							alignItems="center"
							key={tab.key}
							onClick={() => setActiveTabAndNavigate(tab)}
							bg={ProjectConfig.ARCA_COLORS.WHITE}
							borderRadius="md"
							m={1}
							p={1}
							fontWeight={activeTab === tab.key ? 'bold' : 'normal'}
							w={isMenuOpen ? 'full' : 'auto'}
							paddingBlock={8}>
							<Box
								bg={activeTab === tab.key ? ProjectConfig.ARCA_COLORS.PINK_100 : ''}
								borderRadius="md"
								display="flex"
								alignItems="center"
								justifyContent="center"
								padding={4}>
								{tab.icon}
							</Box>
							{isMenuOpen && (
								<Box paddingLeft={6}>
									{
										<Text
											fontFamily={ProjectConfig.FONTS.SPACE_GROTESK}
											fontSize="20px"
											fontWeight="900"
											height="34px">
											{tab.label}
										</Text>
									}
								</Box>
							)}
						</Button>
					))}
				</Box>
				{/* TABS */}

				{/* BY ARCA */}

				<Box
					mt={1}
					display="flex"
					justifyContent="center"
					flexDirection="column"
					alignItems="center">
					{isMenuOpen ? (
						<Box display="flex" alignItems="center" height="72px">
							{' '}
							<Box ml="4">
								<Text fontWeight="bold" fontSize={18} marginEnd={5}>
									{translate('generic.dashboard.poweredBy')}
								</Text>
							</Box>
							<Avatar size="md" name={currentUser?.user.name || ''} src="/imgs/ICONO_RRSS.png" />
						</Box>
					) : (
						<Box display="flex" alignItems="center" height="72px">
							{' '}
							<Avatar size="md" name={currentUser?.user.name || ''} src="/imgs/ICONO_RRSS.png" />
						</Box>
					)}

					{/* BY ARCA */}

					{/* LOGOUT */}

					{isMenuOpen ? (
						<Button
							alignSelf="flex-end"
							// mb="20px"
							onClick={handleLogout}
							bg={ProjectConfig.ARCA_COLORS.WHITE}
							_hover={{ color: ProjectConfig.ARCA_COLORS.PINK_100 }}>
							<TbLogout />
							<Box ml="10px">{translate('general.button.logOut')}</Box>
						</Button>
					) : (
						<Button
							width="14%"
							onClick={handleLogout}
							bg={ProjectConfig.ARCA_COLORS.WHITE}
							_hover={{ color: ProjectConfig.ARCA_COLORS.PINK_100 }}>
							<Box fontSize="28px" fontWeight="bold">
								<TbLogout />
							</Box>
						</Button>
					)}
					{/* LOGOUT */}
				</Box>
			</VStack>

			<Box
				zIndex="1"
				p={4}
				bg={ProjectConfig.ARCA_COLORS.LIGHT_GREY_02}
				flexGrow={1}
				h="100%"
				minH="100vh">
				{getActiveComponent(activeTab)}
			</Box>
			{/* Botón de contacto flotante */}
			<Button
				onClick={handleChatClick}
				position="fixed"
				bottom="20px"
				right="20px"
				zIndex="999" // Asegura que el botón de chat esté en la parte superior
				width="60px"
				height="60px"
				borderRadius="50%"
				bg={ProjectConfig.ARCA_COLORS.PINK_500}
				color="white"
				_hover={{ bg: ProjectConfig.ARCA_COLORS.PINK_600 }}
				display="flex"
				justifyContent="center"
				alignItems="center"
				boxShadow="lg">
				<AiOutlineMessage size="24px" />
			</Button>
			{/* Botón de contacto flotante */}
			{/* Modal para el chat */}
			<Modal isOpen={isChatOpen} onClose={handleChatClick} size="md">
				<ModalOverlay />
				<ModalContent position="fixed" bottom="90px" right="20px">
					<ModalHeader>{translate('general.chat.title')}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Box>{translate('general.chat.message')} </Box>
						<Button
							as="a"
							href="mailto:soporte@arcadigital.app"
							mt={4}
							width="100%"
							colorScheme="pink">
							{translate('general.chat.contact')}
						</Button>
						<Button as="a" href="tel:686288838" mt={4} width="100%" colorScheme="green">
							{translate('general.chat.call')}
						</Button>
					</ModalBody>
				</ModalContent>
			</Modal>
			{/* Modal para el chat */}
		</Flex>
	);
};

export default Dashboard;
