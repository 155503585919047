import React from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';
import ProjectConfig from '../../constants';

interface ArcaPrimaryButtonProps extends ButtonProps {
	color?: string;
	backgroundColor?: string;
	_hover?: object;
	onClick?: () => void;
	children: React.ReactNode;
	icon?: string;
}

const ArcaPrimaryButton: React.FC<ArcaPrimaryButtonProps> = ({
	color = ProjectConfig.ARCA_COLORS.BLACK,
	backgroundColor = ProjectConfig.ARCA_COLORS.PINK_200,
	_hover = {
		bg: ProjectConfig.ARCA_COLORS.PINK_400,
		color: ProjectConfig.ARCA_COLORS.WHITE
	},

	onClick,
	children,
	icon,
	...props
}) => {
	return (
		<Button
			color={color}
			backgroundColor={backgroundColor}
			_hover={_hover}
			onClick={onClick}
			w="fit-content"
			px="30px"
			mx="auto"
			borderRadius="8px"
			{...props}>
			{children}
			{icon && (
				<img src={icon} alt="icon" style={{ marginLeft: '10px', width: '35px', height: '35px' }} />
			)}
		</Button>
	);
};

export default ArcaPrimaryButton;
